import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../tools/i18n';
import ButtonV2 from '../../ButtonV2';
import { Picto } from '../../Picto';
import { StickyItem } from '../../StickyItem';
import ContainerShopV2 from '../Container';

export interface ManagementStoreV2Type {
  name?: string;
  email?: string;
  website?: string;
  updatedAt?: string;
  createdAt?: string;
}

interface ManagementStoreV2Props extends ManagementStoreV2Type {
  noContainer?: boolean;
}

const ManagementStoreV2: React.FC<ManagementStoreV2Props> = ({
  name,
  email,
  website,
  createdAt,
  updatedAt,
  noContainer,
}) => {
  const { t } = useTranslation();
  const [sellerName, setSellerName] = useState('');

  useEffect(() => {
    const name = document.querySelector('.ShopV2Card__container__title');
    if (!name?.textContent) return;

    setSellerName(name.textContent);
  }, []);

  const child = (
    <div className="ManagementStoreV2">
      <div className="ManagementStoreV2__header">
        <p className="ManagementStoreV2__header__label">{t('shopV2.management.proposedBy')}</p>
        <p className="ManagementStoreV2__header__name">{name}</p>
      </div>
      <a
        href={website}
        target="_blank"
        rel="noopener noreferrer"
        className="ManagementStoreV2__website"
      >
        {website}
        <Picto iconKey="arrowRightV2" />
      </a>
      {email ? (
        <StickyItem position="right">
          <ButtonV2
            className="ReportAnomalyButton"
            text={t('shopV2.management.reportAnomaly.button')}
            variant="outline"
            leftIcon="bug"
            link={`mailto:${email}${
              sellerName
                ? `?subject=${t('shopV2.management.reportAnomaly.mail.subject', { sellerName })}
                 &body=${encodeURIComponent(t('shopV2.management.reportAnomaly.mail.body', { sellerName }))}`
                : ''
            }`}
          />
        </StickyItem>
      ) : null}
    </div>
  );

  return noContainer ? child : <ContainerShopV2>{child}</ContainerShopV2>;
};

export default ManagementStoreV2;
