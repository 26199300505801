import React, { Fragment, useState } from 'react';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import { A11y, Keyboard, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import ClientSideSlideshowLightbox from '../ClientSideSlideshowLightbox';
import { Picto } from '../Picto';

interface ImagesGalleryCarouselProps {
  imageUrls: string[];
  swiperProps?: SwiperProps;
}

const ImagesGalleryCarousel: React.FC<ImagesGalleryCarouselProps> = ({
  imageUrls,
  swiperProps,
}: ImagesGalleryCarouselProps) => {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(-1);

  if (!imageUrls?.length) return null;

  const imagesGroup: string[][] = [];
  let group: string[] = [];
  imageUrls.forEach((imageUrl, index) => {
    group.push(imageUrl);
    if (group.length === 3) {
      imagesGroup.push(group);
      group = [];
    }
  });
  if (group.length) imagesGroup.push(group);

  return (
    <div className="ImagesGalleryCarousel">
      <div
        className={`ImagesGalleryCarousel__carousel ImagesGalleryCarousel__carousel--${imagesGroup.length}`}
      >
        <Swiper
          modules={[Keyboard, Navigation, Pagination, A11y]}
          onSwiper={setSwiper}
          onSlideChange={(swiper: any) => setActiveIndex(swiper.realIndex)}
          loop
          spaceBetween={20}
          {...swiperProps}
        >
          {imagesGroup.map((group, groupIndex) => (
            <SwiperSlide
              key={groupIndex}
              className={`ImagesGalleryCarousel__carousel__slide ImagesGalleryCarousel__carousel__slide--${group.length}`}
            >
              {group.map((imageUrl, imageIndex) => {
                const index = groupIndex * 3 + imageIndex;

                return (
                  <img
                    key={imageIndex}
                    src={imageUrl}
                    alt={`${index}`}
                    onClick={() => setLightboxImageIndex(index)}
                  />
                );
              })}
            </SwiperSlide>
          ))}
        </Swiper>
        {imagesGroup.length > 1 ? (
          <Fragment>
            <div className="ImagesGalleryCarousel__carousel__pagination">
              {imagesGroup.map((_, index) => (
                <button
                  key={index}
                  className={`ImagesGalleryCarousel__carousel__pagination__button ${
                    activeIndex === index
                      ? 'ImagesGalleryCarousel__carousel__pagination__button--active'
                      : ''
                  }`}
                  onClick={() => swiper?.slideToLoop(index)}
                />
              ))}
            </div>
            <button
              className="ImagesGalleryCarousel__carousel__navigationButton ImagesGalleryCarousel__carousel__navigationPrev"
              onClick={() => swiper?.slidePrev()}
            >
              <Picto iconKey="arrowLeftV2" />
            </button>
            <button
              className="ImagesGalleryCarousel__carousel__navigationButton ImagesGalleryCarousel__carousel__navigationNext"
              onClick={() => swiper?.slideNext()}
            >
              <Picto iconKey="arrowRightV2" />
            </button>
          </Fragment>
        ) : null}
      </div>
      <ClientSideSlideshowLightbox
        images={imageUrls?.map((imageUrl, i) => ({ src: imageUrl, alt: i.toString() }))}
        startingSlideIndex={lightboxImageIndex}
        open={lightboxImageIndex > -1}
        onClose={() => setLightboxImageIndex(-1)}
      />
    </div>
  );
};

export default ImagesGalleryCarousel;
