import React from 'react';
import Marquee from 'react-fast-marquee';
import { v4 as uuidv4 } from 'uuid';
import { RequirementsProps } from '../../../tools/context';
import { imageUrlFor } from '../../../tools/sanity';
import SearchBoxIA from './SearchBoxIA';
import { eventTracking } from '../../../tools/eventTracking';
import { Copyright } from '../../Copyright';

interface Suggestion {
  _key?: string;
  text: string;
  link: string;
}

interface HeroSuggestionsProps {
  backgroundImage: {
    asset: {
      _id?: string;
      altText?: string;
      title?: string;
      description?: string;
    };
    alt?: string;
    tags?: string[];
    caption?: string;
  };
  title: string;
  subtitle: string;
  suggestions: Suggestion[];
  requirements?: RequirementsProps;
  siteConfiguration: any;
  opacityColor?: string;
  opacityValue?: number;
}

export const HeroSuggestions: React.FC<HeroSuggestionsProps> = ({
  backgroundImage,
  title,
  subtitle,
  suggestions,
  requirements,
  siteConfiguration,
}) => {
  const placeholderSentences = siteConfiguration.placeholderSentences;
  console.log(backgroundImage);

  return (
    <div className="HeroSuggestions">
      <div className="HeroSuggestions__background__container">
        <img
          className="HeroSuggestions__background"
          loading="lazy"
          srcSet={`${imageUrlFor(backgroundImage, requirements)
            .width(400)
            .format('webp')
            .url()} 400w, 
            ${imageUrlFor(backgroundImage, requirements)
            .width(800)
            .format('webp')
            .url()} 800w,
            ${imageUrlFor(backgroundImage, requirements)
            .width(1200)
            .format('webp')
            .url()} 1200w,
                        `}
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1200px"
          src={imageUrlFor(backgroundImage, requirements)
            .height(500)
            .format('webp')
            .url()}
          alt=''
        />

      </div>
      {backgroundImage?.asset?.title && (
        <Copyright title={backgroundImage.asset.title} className="HeroSuggestions__copyright" />
      )}
      <div className="container">
        <h1 className="title">{title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
        <SearchBoxIA placeholderSentences={placeholderSentences} />
      </div>
      {suggestions && suggestions.length > 0 && (
        <div className="suggestions-container">
          <Marquee speed={60} delay={0} loop={0} pauseOnHover={true}>
            <ul className="suggestions-container__list">
              {suggestions.map((suggestion, index) => (
                <li className="suggestions-container__list__item" key={suggestion._key || index}>
                  <a
                    onClick={() => eventTracking("interaction", "clic-suggestion", "clic-suggestion")}
                    href={`/engine#/engine/charentes-engine/chat?chat_id=${uuidv4()}&query=${encodeURIComponent(suggestion.text)}`}
                  >
                    {suggestion.text}
                  </a>
                </li>
              ))}
            </ul>
          </Marquee>
        </div>
      )}
    </div>
  );
};

export default HeroSuggestions;
