import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import React, { useState } from 'react';
import { RequirementsProps } from '../../tools/context';
import { imageUrlFor } from '../../tools/sanity';
import ClientSideSlideshowLightbox from '../ClientSideSlideshowLightbox';
import { Copyright } from '../Copyright';

interface Image {
  alt?: string;
  credit?: string;
  image: SanityImageObject;
  _key: string;
}

type ImageGroupWidth = 'normal' | 'large';

interface ImagesGroupProps {
  requirements: RequirementsProps;
  width: ImageGroupWidth;
  images: Image[];
}

export default function ImagesGroup({ requirements, width, images }: ImagesGroupProps) {
  const [lightboxImageIndex, setLightboxImageIndex] = useState(-1);
  const imagesWithSources = images?.map((image, index) => ({
    ...image,
    src: imageUrlFor(image.image, requirements).url(),
    alt: image.alt || `Image ${index + 1}`,
  }));

  return (
    <>
      <div className={`ImagesGroup ImagesGroup--${width} ImagesGroup--${images.length}`}>
        {imagesWithSources?.map((image, index) => (
          <button
            style={{position: 'relative'}}
            key={index}
            className="ImagesGroup__imageContainer"
            onClick={() => {
              console.log('clicked');
              setLightboxImageIndex(index);
            }}
          >
            <img
              key={image._key}
              src={image.src}
              alt={image.alt || ''}
              className="ImagesGroup__imageContainer__image"
            />
            {image?.credit && (
              <Copyright title={image.credit} className="ImagesGroup__copyright" />
            )}
          </button>
        ))}
      </div>
      <ClientSideSlideshowLightbox
        images={imagesWithSources}
        startingSlideIndex={lightboxImageIndex}
        open={lightboxImageIndex > -1}
        onClose={() => setLightboxImageIndex(-1)}
      />
    </>
  );
}
