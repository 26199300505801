import * as React from 'react';
import { Fragment, useState } from 'react';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import { A11y, Keyboard, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { RequirementsProps } from '../../../tools/context';
import { useWindowSize } from '../../../tools/hooks';
import { useTranslation } from '../../../tools/i18n';
import { getSanityClient, SanityColor } from '../../../tools/sanity';
import { Picto } from '../../Picto';
import ArticleCard, { ArticleCardProps } from './ArticleCard';
import ArticlesCarouselHeader from './Header';
import { ArticlesCarouselLabelProps } from './Label';
import { ArticlesCarouselLinkProps } from './Link';

export interface ArticlesCarouselProps {
  componentStates: {
    articles?: ArticleCardProps[];
  };
  requirements: RequirementsProps;
  label: ArticlesCarouselLabelProps;
  title?: string;
  link?: ArticlesCarouselLinkProps;
  background?: SanityColor;
}

const Renderer: React.FC<ArticlesCarouselProps> = ({
  componentStates: { articles },
  requirements,
  label,
  title,
  link,
  background: { hex: backgroundColor },
}) => {
  const { isDesktop, width } = useWindowSize();
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const hasLabel = !!label.picto || !!label.text;
  const hasLink = !!link.text;
  const hasHeader = hasLabel || !!title || hasLink;

  return (
    <div className="ArticlesCarousel" style={{ backgroundColor: backgroundColor }}>
      {hasHeader ? <ArticlesCarouselHeader label={label} title={title} link={link} /> : null}
      <div className="ArticlesCarousel__carousel">
        <Swiper
          modules={[A11y, Keyboard, Navigation]}
          onSwiper={setSwiper}
          onSlideChange={(swiper: any) => setActiveIndex(swiper.realIndex)}
          slidesPerView={isDesktop ? 2.2 : width < 600 ? 1.15 : 2.3}
          spaceBetween={isDesktop ? 20 : 10}
          centeredSlides={!isDesktop}
          simulateTouch={false}
        >
          {articles.map((article, index) => (
            <SwiperSlide key={index}>
              <ArticleCard requirements={requirements} {...article} />
            </SwiperSlide>
          ))}
          {isDesktop ? (
            <Fragment>
              <SwiperSlide />
              <SwiperSlide />
            </Fragment>
          ) : null}
        </Swiper>
        <div className="ArticlesCarousel__carousel__pagination">
          {articles.map((_, index) => (
            <button
              key={index}
              className={`ArticlesCarousel__carousel__pagination__button ${
                activeIndex === index
                  ? 'ArticlesCarousel__carousel__pagination__button--active'
                  : ''
              }`}
              onClick={() => swiper?.slideTo(index)}
            />
          ))}
        </div>
        {activeIndex !== 0 ? (
          <button
            className="ArticlesCarousel__carousel__navigationButton ArticlesCarousel__carousel__navigationPrev"
            onClick={() => swiper.slidePrev()}
          >
            <Picto iconKey="arrowLeftV2" />
          </button>
        ) : null}
        {activeIndex < articles?.length - 1 ? (
          <button
            className="ArticlesCarousel__carousel__navigationButton ArticlesCarousel__carousel__navigationNext"
            onClick={() => swiper.slideNext()}
          >
            <Picto iconKey="arrowRightV2" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

const preloader = async ({ articles: articlesRef, ...rest }, requirements: RequirementsProps) => {
  if (!requirements.parameters.length) return null;

  const client = getSanityClient(requirements);
  const query = `*[_type == "article" && (_id in [${articlesRef.map((article: any) => `"${article._ref}"`).join(',')}])]`;
  const articles = await client.fetch(query);

  return {
    ...rest,
    articles,
  };
};

const ArticlesCarousel = { Renderer, preloader };

export default ArticlesCarousel;
export { Renderer, preloader };
