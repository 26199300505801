import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

interface FormattedAxiosRequest {
  url: string;
  method: AxiosRequestConfig['method'];
  headers: AxiosRequestConfig['headers'];
  data: AxiosRequestConfig['data'];
}
export type FormatAxiosRequestFn = (request: InternalAxiosRequestConfig) => FormattedAxiosRequest;

interface FormattedAxiosResponse {
  url: string;
  status: number;
  headers: AxiosError['request']['responseHeaders'];
  data: any;
}
export type FormatAxiosResponseFn = (response: AxiosError['request']) => FormattedAxiosResponse;

interface FormattedAxiosError {
  url: AxiosError['request']['responseURL'];
  responseHeaders: AxiosError['request']['responseHeaders'];
  status?: number;
  data: any;
}
export type FormatAxiosErrorFn = (error: AxiosError<any>) => FormattedAxiosError;

export const formatAxiosRequest: FormatAxiosRequestFn = (request) => {
  return {
    url: `${request.baseURL}${request.url}`,
    method: request.method,
    headers: request.headers,
    data: request.data,
  };
};

export const formatAxiosResponse: FormatAxiosResponseFn = (response) => {
  return {
    url: response.config.url,
    status: response.status,
    headers: response.headers,
    data: response.data,
  };
};

export const formatAxiosError: FormatAxiosErrorFn = (error) => {
  return {
    url: error.request?.responseURL,
    responseHeaders: error.request?.responseHeaders,
    status: error.response?.status,
    data: error.response?.data,
  };
};

export const createAxiosInstance = (config: any) => {
  const instance = axios.create(config);

  process.env.NODE_ENV === 'development' &&
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.error('Error:', JSON.stringify(formatAxiosError(error), null, 2));
        return Promise.reject(error);
      }
    );

  process.env.NODE_ENV === 'development' &&
    instance.interceptors.request.use((request) => {
      console.log('Starting Request', JSON.stringify(formatAxiosRequest(request), null, 2));
      return request;
    });

  return instance;
};
