import React, { Fragment } from 'react';
import { useTranslation } from '../../../tools/i18n';
import { CustomStoreGroup } from '../../../tools/providersAPI';
import ContainerShopV2 from '../Container';
import DetailsStoreItemComp from './Details';
import TableStoreItemComp from './Table';
import TextStoreItemComp from './Text';
import TextListStoreItemComp from './TextList';

export interface GroupsShopV2Props {
  groups: CustomStoreGroup[];
}

const GroupsShopV2: React.FC<GroupsShopV2Props> = ({ groups = [] }) => {
  const { t } = useTranslation();

  if (!groups?.length) return null;

  const groupTypes = {
    text: TextStoreItemComp,
    textList: TextListStoreItemComp,
    details: DetailsStoreItemComp,
    table: TableStoreItemComp,
  };

  return (
    <Fragment>
      {groups.map((group, index) =>
        group?.items?.length ? (
          <ContainerShopV2
            key={index}
            id={group.anchor}
            title={group.title?.replace(/{{t:(.*?)}}/g, (match, key) =>
              t(`shopV2.groupsCustom.titles.${key}`)
            )}
          >
            <div className="GroupsShopV2__items">
              {group.items?.map((item, i) => {
                if (!item?.type) return null;

                const Component = groupTypes[item.type] || null;
                if (!Component) return null;

                return <Component key={i} {...item} />;
              })}
            </div>
          </ContainerShopV2>
        ) : null
      )}
    </Fragment>
  );
};

export default GroupsShopV2;
