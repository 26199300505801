import React, { useContext, useState } from 'react';
import { OverLayParameter } from '../../App';
import { useWindowSize } from '../../tools/hooks';
import { useTranslation } from '../../tools/i18n';
import { Button } from '../Button';
import { ChatAgent } from '../ChatAgent';
import Favorites from './Favorites';
import { simplifyTrip } from './index';
import PrintPDFButton from './PDF/PrintButton';
import Share from './Share';
import { TravelPlanerResultsContext } from './TravelPlanerResultsContext';

export interface HeaderProps {
  componentStates: any;
  setAgentOverlay: (p: OverLayParameter) => void;
  setDays: (d: string) => void;
}

export const Header = (props: HeaderProps) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  const { componentStates, setAgentOverlay, setDays } = props;
  const { agentId, config, formattedData, selectedActivities, trip, requirements } = useContext(
    TravelPlanerResultsContext
  );
  const { journey: results, restaurants } = componentStates;
  const { inputs } = results || {};
  const [activeAnchor, setActiveAnchor] = useState<string>('restaurants');
  const { t } = useTranslation();

  const scrollToElementWithOffset = (element: any, offset: number) => {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const scrollToRestaurants = () => {
    const restaurants = document.querySelector('.Restaurants');
    if (!restaurants) return;
    scrollToElementWithOffset(restaurants, 250);
    setActiveAnchor('restaurants');
  };

  const scrollToItinerary = () => {
    const itinerary = document.querySelector('.travelDays');
    if (!itinerary) return;
    scrollToElementWithOffset(itinerary, 250);
    setActiveAnchor('itinerary');
  };

  const scrollToDay = (day: string) => {
    const days = document.querySelector('.travelDays');
    if (!days) return;
    scrollToElementWithOffset(days, 300);
    setActiveAnchor(day);
  };

  const agentContext = simplifyTrip(
    Object.keys(formattedData)?.map((key: string) =>
      formattedData[key]?.map((d: any, index: number) => ({
        key: index,
        value: d.value?.map((v: any) => ({
          ...v,
          time: index,
        })),
      }))
    ),
    selectedActivities
  );

  return (
    <div className="header">
      <div className="header-container">
        {config.showRestaurants && restaurants?.length ? (
          <div className="left">
            {config.hideChatAgent || !config.chatAgentInHeader || !isMobile ? undefined : (
              <ChatAgent
                key={JSON.stringify(formattedData) + JSON.stringify(selectedActivities)}
                requirements={requirements}
                agentId={agentId}
                context={agentContext}
                setOverlay={setAgentOverlay}
                withLabel
                hideHighlight
              />
            )}
            <div className="days-container">
              <Button
                theme={'pro'}
                className={`${activeAnchor === 'restaurants' ? 'active' : ''}`}
                onClick={scrollToRestaurants}
              >
                {t('travelPlaner.Travel.whereToEat')}
              </Button>
              {config.showDaysInHeader ? (
                <div className="days">
                  {Object.keys(trip).map((r, i) => (
                    <Button
                      key={`daylabel_${encodeURIComponent(r)}`}
                      theme={'pro'}
                      onClick={() => {
                        setDays(r);
                        scrollToDay(r);
                      }}
                      className={`${activeAnchor === r ? 'active' : ''}`}
                    >
                      {`${t('travelPlaner.Travel.day')} ${i + 1}`}
                    </Button>
                  ))}
                </div>
              ) : (
                <Button
                  theme={'pro'}
                  className={`${activeAnchor === 'itinerary' ? 'active' : ''}`}
                  onClick={scrollToItinerary}
                >
                  {t('travelPlaner.Travel.title')}
                </Button>
              )}
              <div className="right">
                {config.hideChatAgent || !config.chatAgentInHeader || isMobile ? undefined : (
                  <ChatAgent
                    key={JSON.stringify(formattedData) + JSON.stringify(selectedActivities)}
                    requirements={requirements}
                    agentId={agentId}
                    context={agentContext}
                    setOverlay={setAgentOverlay}
                    withLabel
                    hideHighlight
                  />
                )}
                {config.showPrintInHeader ? (
                  <PrintPDFButton
                    trip={trip}
                    componentStates={componentStates}
                    requirements={requirements}
                    config={config}
                  />
                ) : null}
                {config.showFavorites ? <Favorites inputs={inputs} /> : null}
                {config.showShare ? <Share /> : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
