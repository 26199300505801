import React from 'react';
import { RequirementsProps } from '../../../../../tools/context';

interface NewsletterPageProps {
  requirements: RequirementsProps;
}

const NewsletterPage = (_: NewsletterPageProps) => {
  return <div className="NewsletterPage">Newsletter Page</div>;
};

export default NewsletterPage;
