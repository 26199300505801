import React, { Fragment } from 'react';
import { Picto, PictoProps, pictos } from '../Picto';
import { Loader } from '../Picto/Loader';

export interface ButtonV2Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text?: string;
  leftIcon?: keyof typeof pictos;
  rightIcon?: keyof typeof pictos;
  leftIconProps?: PictoProps;
  rightIconProps?: PictoProps;
  align?: 'left' | 'center' | 'right';
  variant?: 'solid' | 'outline' | 'clear';
  isLoading?: boolean;
  link?: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >['href'];
  linkTarget?: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >['target'];
  children?: React.ReactNode;
}

const ButtonV2: React.FC<ButtonV2Props> = ({
  text,
  leftIcon,
  rightIcon,
  align = 'center',
  variant = 'solid',
  isLoading,
  link,
  linkTarget,
  className,
  children,
  ...rest
}) => {
  const TextComp = link ? 'a' : 'button';
  const compProps = link
    ? { href: link, target: (linkTarget ?? link.startsWith('/')) ? '_self' : '_blank' }
    : {};

  return (
    // @ts-ignore
    <TextComp
      className={`ButtonV2 ButtonV2--${variant} ButtonV2--${align} ${link ? 'ButtonV2--link' : ''} ${className ?? ''}`}
      {...compProps}
      {...rest}
    >
      {isLoading ? (
        <div className="ButtonV2__loader">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {leftIcon ? <Picto iconKey={leftIcon} /> : null}
          {text ? <span className="ButtonV2__text">{text}</span> : null}
          {children ? children : null}
          {rightIcon ? <Picto iconKey={rightIcon} /> : null}
        </Fragment>
      )}
    </TextComp>
  );
};

export default ButtonV2;
