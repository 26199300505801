import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import * as Configurations from '../configurations';
import { RequirementsProps } from './context';

export interface SanityColor {
  alpha: number;
  hex: string;
  hsl: { a: number; h: number; l: number; s: number; _type: 'hslaColor' };
  hsv: { a: number; h: number; s: number; v: number; _type: 'hsvaColor' };
  rgb: { a: number; r: number; g: number; b: number; _type: 'rgbaColor' };
}

const getSanityClient = (requirements: RequirementsProps) => {
  if (!requirements || !requirements.config) return sanityClient(Configurations.tca.sanity.base);

  const config = requirements.config.sanity;

  if (
    requirements.host.startsWith('localhost') ||
    requirements.host.startsWith('qa.') ||
    requirements.host.startsWith('dev.')
  ) {
    return sanityClient({ ...config.base, ...config.preview });
  }

  return sanityClient(config.base);
};

const imageUrlFor = (source: SanityImageSource, requirements: RequirementsProps) => {
  const sanityClient = getSanityClient(requirements);
  const emptyImageUrlBuilder = {
    url: () => '',
    auto: () => emptyImageUrlBuilder,
    quality: () => emptyImageUrlBuilder,
    width: () => emptyImageUrlBuilder,
    height: () => emptyImageUrlBuilder,
    fit: () => emptyImageUrlBuilder,
    format: () => emptyImageUrlBuilder,
  };
  if (!sanityClient) return emptyImageUrlBuilder;

  return imageUrlBuilder(sanityClient).image(source).auto('format').quality(100);
};

export { getSanityClient, imageUrlFor };
