import * as React from 'react';
import { useState } from 'react';
import { subToNewsletter } from '../../tools/ciblerAPI';
import { RequirementsProps } from '../../tools/context';
import { useTranslation } from '../../tools/i18n';
import Checkbox from '../Checkbox';

export interface NewsletterV2Props {
  requirements: RequirementsProps;
  title: string;
  optInText?: string;
}

const NewsletterV2: React.FC<NewsletterV2Props> = ({ requirements, title, optInText }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>(null);
  const [sent, setSent] = useState<boolean>(false);

  const sub = () => {
    console.log('sub', email, requirements);
    subToNewsletter(email, requirements)
      .then(() => setSent(true))
      .catch(() => setSent(false));
  };

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <form
      className="NewsletterV2"
      onSubmit={(e) => {
        e.preventDefault();
        sub();
        // @ts-ignore
        e.target.reset();
      }}
    >
      <div className="NewsletterV2__container">
        <div className="NewsletterV2__header">
          <div className="NewsletterV2__header__hat">
            <div className="NewsletterV2__header__hat__circle" />
            <p className="NewsletterV2__header__hat__text">NEWSLETTER</p>
          </div>
          <p className="NewsletterV2__header__title">{title}</p>
        </div>
        <div className="NewsletterV2__inputContainer">
          <input
            className="NewsletterV2__input"
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            className="NewsletterV2__subButton"
            type="submit"
            disabled={sent || !isValidEmail(email)}
          >
            {t(`ssrComponents.newsletterV2.${sent ? 'subscribed' : 'subscribe'}`)}
          </button>
        </div>
        {!!optInText ? <Checkbox id="optIn" required label={optInText} /> : null}
      </div>
    </form>
  );
};

export default NewsletterV2;
