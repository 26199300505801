import React, { Fragment, useEffect, useState } from 'react';

import { RequirementsProps } from '../../tools/context';
import { Button } from '../Button';
import Logo from '../Logo';
import { Picto } from '../Picto';
import LanguageSelector from './LanguageSelector';
import Navigation from './navigation';

import { PAGE_ROUTES } from '../../App';
import { useWindowSize } from '../../tools/hooks';
import FullScreenMenu from '../Navigation';
import { Bell, UserCharentes } from '../Picto/charentes/Icons';

interface ImageAsset {
  _ref: string;
  _type: 'reference';
}

interface ImageObject {
  asset: ImageAsset;
  alt?: string;
}

export interface HeaderProps {
  navigation: any[];
  headerButtons: any[];
  requirements: RequirementsProps;
  headerType?: 'classic' | 'modern';
  header?: any;
  logo?: ImageObject;
  backgroundColor?: string;
}

export const ClassicHeader = (props: HeaderProps) => {
  const [showNav, setShowNav] = useState<boolean>(false);
  const { navigation, headerButtons, requirements } = props;

  const agentLinksMatch = [
    { pathInclude: '-vin', linkSuffix: '-vin', links: ['/guide', '/itineraire'] },
  ];

  const getLink = (link: string) => {
    let suffix = '';
    agentLinksMatch.forEach((match) => {
      if (!requirements.path.includes(match.pathInclude)) return link;
      match.links.forEach((l) => {
        if (l === link) suffix = match.linkSuffix;
      });
    });
    return link + suffix;
  };

  headerButtons?.forEach((button) => {
    button.link = getLink(button.link);
  });

  navigation?.forEach((nav) => {
    nav.link = getLink(nav.link);
  });

  return (
    <div className="Header">
      <div className="header-content">
        <Logo className="header-logo" location={'header'} requirements={requirements} />
        <div className="container">
          <div className="container">
            {headerButtons &&
              headerButtons.map((h) => (
                <a
                  className={`Button navButton ${h.style} ${h.picto ? 'hasPicto' : ''}`}
                  href={h.link}
                  key={h._key}
                  target={h.external ? '_blank' : null}
                  rel={h.external ? 'noreferrer' : null}
                >
                  {h.picto && <Picto iconKey={h.picto} />}
                  <span>{h.text}</span>
                </a>
              ))}
          </div>
          <Button className="burger-menu" theme={'pro'} onClick={() => setShowNav(true)}>
            <Picto iconKey="burger-menu-2" />
          </Button>
          {requirements.config.customerId &&
            requirements.endpoint &&
            requirements.config.languageSelector && (
              <LanguageSelector
                customerID={requirements.config.customerId}
                endpoint={requirements.endpoint}
              />
            )}
        </div>
      </div>
      {showNav && (
        <Navigation
          requirements={requirements}
          closeCallback={() => setShowNav(false)}
          navigation={navigation}
        />
      )}
    </div>
  );
};

const FIXED_ON_PAGE = ['/'];
const DO_NOT_HIDE_ON_SCROLL_UP = ['/etablissement'];

export const ModernHeader = (props: HeaderProps) => {
  const svgMarkup = { __html: props.header?.logo?.svg };
  const isFixed = FIXED_ON_PAGE.includes(props.requirements?.path);
  const isShownOnScroll = DO_NOT_HIDE_ON_SCROLL_UP.includes(props.requirements?.path);
  const backgroundColor = isFixed ? '' : props.header?.backgroundColor?.hex;
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const logoLink = props.header?.logo?.link || '/';
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0';
    };
  }, [isMenuOpen]);

  useEffect(() => {
    let lastScroll = 0;

    const handleScroll = () => {
      const headerHeight = document.getElementById('modern-header')?.clientHeight || 0;
      const currentScroll = window.scrollY;

      setIsScrolled(currentScroll > headerHeight - 40);

      if (currentScroll <= 0) {
        setIsHidden(false);
      } else if (
        !isShownOnScroll &&
        currentScroll < lastScroll &&
        currentScroll > headerHeight + 40
      ) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }

      lastScroll = currentScroll;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`c-Header ${isScrolled ? 'is-scrolled' : ''} ${isFixed ? 'is-fixed' : ''} ${
        isHidden ? 'is-hidden' : ''
      }`}
      style={{ backgroundColor }}
      id="modern-header"
    >
      <a id="header-logo-link" href={logoLink} style={{ zIndex: 1001 }}>
        <div
          className={`header-logo ${isMenuOpen ? 'is-open' : ''}`}
          dangerouslySetInnerHTML={svgMarkup}
        />
      </a>
      <div id="c-Header-insert">
        <div id="c-Header-insert-1" />
        <div id="c-Header-insert-2-container">
          <div id="c-Header-insert-2" />
          <div className="separator-line" />
        </div>
      </div>
      <ul className={`c-Header-items ${isScrolled ? 'is-scrolled' : ''}`} id="breadcrumb">
        {isMobile ? null : (
          <Fragment>
            <li className="c-Header-item account">
              <a href={PAGE_ROUTES.AUTH.ACCOUNT}>
                <UserCharentes />
              </a>
            </li>
            <li className="c-Header-item bell">
              <a href={'https://www.google.com'}>
                <Bell />
              </a>
            </li>
          </Fragment>
        )}
        <div
          className={`c-Header-mobile-menu ${isMenuOpen ? 'is-open' : ''}`}
          id="mobile-menu"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <div className="c-Header-mobile-menu-line top"></div>
          <div className="c-Header-mobile-menu-line middle"></div>
          <div className="c-Header-mobile-menu-line bottom"></div>
        </div>
        <FullScreenMenu isOpen={isMenuOpen} closeMenu={() => setMenuOpen(false)} />
      </ul>
    </div>
  );
};

export const Header = (props: HeaderProps) => {
  const { headerType } = props || { headerType: 'classic' };
  return headerType === 'modern' ? <ModernHeader {...props} /> : <ClassicHeader {...props} />;
};
