import React from 'react';

import { RequirementsProps } from '../../tools/context';
import { imageUrlFor } from '../../tools/sanity';

export interface BannerImageProps {
  image: any;
  imageMobile?: any;
  title: string;
  link?: string;
  requirements: RequirementsProps;
}

const BannerImage = (props: BannerImageProps) => {
  const { image, imageMobile, link, title, requirements } = props;

  return (
    <a className="bannerImage" href={link}>
      {image && (
        <img
          className="lazyload desktop"
          src={imageUrlFor(image, requirements).url()}
          alt={title}
        />
      )}
      {(image || imageMobile) && (
        <img
          className="lazyload mobile"
          src={imageUrlFor(imageMobile || image, requirements).url()}
          alt={title}
        />
      )}
    </a>
  );
};

export default BannerImage;
