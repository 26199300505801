import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  DirectionValidData,
  isDirectionOk,
  MapboxLine,
  Profile,
  Route,
} from "../../tools/mapbox/apiTypes/direction";
import { mapboxDirectionApi } from "../../tools/mapbox";

export type Point = {
  latitude: number;
  longitude: number;
};

export type DirectionContextValue = {
  direction?: DirectionValidData;
  updateDirection?: (points: Point[], profile: Profile) => Promise<void>;
  clearDirection?: () => void;
  selectedRoute?: Route | undefined;
  selectedRouteGeometry?: MapboxLine;
  currentProfile?: Profile | undefined;
  directionError?: string;
};

export const DirectionContext = createContext<DirectionContextValue>({});

export function DirectionProvider(props: PropsWithChildren<{}>) {
  const [direction, setDirection] = useState<DirectionValidData>();
  const [directionError, setDirectionError] = useState<string>();
  const [currentProfile, setCurrentProfile] = useState<Profile>(
    Profile.DRIVING
  );
  const displayedRouteIndex = 0;
  const selectedRoute = useMemo(
    () => direction?.routes?.[displayedRouteIndex],
    [direction, displayedRouteIndex]
  );
  const selectedRouteGeometry = useMemo(() => {
    const mapboxLine = selectedRoute?.geometry?.coordinates ?? [];
    return mapboxLine.map(([x, y]) => [y, x]) as MapboxLine;
  }, [selectedRoute]);

  const clearDirection = () => {
    setDirection(undefined);
    setCurrentProfile(undefined);
  };

  const updateDirection = useCallback(
    async (points: Point[], profile: Profile) => {
      const { direction: newDirection } = await mapboxDirectionApi(
        profile,
        points
      );
      if (isDirectionOk(newDirection)) {
        setDirection(newDirection);
        setCurrentProfile(profile);
        setDirectionError(undefined);
      } else {
        setDirectionError(newDirection.message);
        clearDirection();
      }
    },
    []
  );

  const contextValue = useMemo(() => {
    return {
      direction,
      updateDirection,
      selectedRoute,
      selectedRouteGeometry,
      currentProfile,
      directionError,
      clearDirection,
    };
  }, [
    direction,
    updateDirection,
    selectedRoute,
    selectedRouteGeometry,
    currentProfile,
    directionError,
  ]);

  return (
    <DirectionContext.Provider value={contextValue}>
      {props.children}
    </DirectionContext.Provider>
  );
}
