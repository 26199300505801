export const mergeFooterConfig = (config: any) => {
  if (!config) return {};

  const footerOptions = config.footerOptions || {};
  const modernFooter = footerOptions.modernFooter || {};

  return {
    ...config.footer,
    ...modernFooter,
    type: footerOptions.type || 'classic',
  };
};
