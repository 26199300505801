import { useEffect, FC  } from 'react';

const WioScript: FC  = () => {
  useEffect(()=>  {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.cibler.io/static/__customerId__/wio.js';
    script.type = 'text/javascript';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return null;
};

export default WioScript;
