import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { Picto } from "../Picto";
import { DROM, INTERNATIONAL_AGENT_ID } from "../../tools/Constants";
import { ChatBotComponentProps } from "./index";
import { ResponsiveImage } from "../ResponsiveImage";
import { FilterLayout } from "./FiltersLayout";
import { useTranslation } from "../../tools/i18n";
import { TravelPlannerFavoriteType, TravelPlannerSearchType } from "./types";

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompleteService = google.maps.places.AutocompleteService;

interface SearchProps extends ChatBotComponentProps {
  step: TravelPlannerSearchType;
}

export const Search = (props: SearchProps) => {
  const { t } = useTranslation();
  const { config, requirements, callback, stateData, setStateData } = props;
  const inputRef = useRef<HTMLInputElement>();
  const voidRef = useRef<HTMLDivElement>();
  const autocomplete = useRef<AutocompleteService>();
  const sessionToken = useRef(null);
  const [predictions, setPredictions] = useState<any[]>([]);
  const { favorites } = config;
  const { agentId } = props;

  const autoCompleteOption = {
    ...(agentId !== INTERNATIONAL_AGENT_ID
      ? { componentRestrictions: { country: ["fr", ...DROM] } }
      : {}),
    fields: ["address_components", "geometry", "name"],
    types: ["locality", "postal_code", "colloquial_area"],
    sessionToken: sessionToken.current,
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.google) {
      return;
    }

    if (agentId) localStorage.setItem("agentId", agentId);

    const loader = new Loader({
      apiKey: "AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8",
      version: "weekly",
      libraries: ["places"],
    });

    loader.load();
    inputRef.current.focus();
  }, [agentId]);

  const getPredictions = async (input: string) => {
    let r = await autocomplete.current.getPlacePredictions({
      input,
      ...autoCompleteOption,
    });
    let results: AutocompletePrediction[] = r.predictions || [];

    return {
      results,
      input,
    };
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setPredictions([]);
      return;
    }

    if (!autocomplete.current) {
      if (typeof window !== "undefined" && window.google) {
        autocomplete.current = new google.maps.places.AutocompleteService();
        sessionToken.current =
          new google.maps.places.AutocompleteSessionToken();
      } else {
        return;
      }
    }

    const value = e.target.value;
    getPredictions(value).then((r) => {
      if (r.input !== inputRef.current.value) return;

      setPredictions(r.results);
    });
  };

  const renderTag = (p: AutocompletePrediction) => {
    let type: { key: string; label: string; icon: string };

    switch (p.types ? p.types[0] : "other") {
      case "locality":
      case "postal_code":
        type = { key: "city", label: "Ville", icon: "city" };
        break;
      case "department":
      case "administrative_area_level_2":
        type = { key: "region", label: "Région", icon: "region" };
        break;
      default:
        type = { key: "other", label: "Autre", icon: "place" };
    }

    return (
      <div className={`tag ${type.key}`}>
        <Picto iconKey={type.icon} />
        {type.label}
      </div>
    );
  };

  const handleSelect = async (p: any) => {
    new google.maps.places.PlacesService(voidRef.current).getDetails(
      {
        placeId: p.place_id,
        fields: autoCompleteOption.fields,
        sessionToken: autoCompleteOption.sessionToken,
      },
      (a) => {
        const temp = { ...stateData };
        temp.lon = a.geometry.location.lng();
        temp.lat = a.geometry.location.lat();
        temp.cityKey = "generic2";
        temp.selectedFavorite = false;
        setStateData(temp);
        callback(p);
      }
    );
  };

  const handleSelectFavorite = (fav: TravelPlannerFavoriteType) => {
    const temp = { ...stateData };
    temp.lon = fav.lng.toString();
    temp.lat = fav.lat.toString();
    temp.cityKey = fav.key;
    temp.selectedFavorite = true;
    setStateData(temp);
    callback(fav);
  };

  return (
    <div className="search">
      <FilterLayout
        config={config}
        title={t(`travelPlaner.Search.title`)}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
      >
        <div className="inputBlock">
          <input
            ref={ref => { inputRef.current = ref }}
            onChange={handleInput}
            placeholder={"Indiquez un lieu"}
          />
          <Picto iconKey="target" />
        </div>
        <div className="predictions">
          {predictions.map((p, i) => (
            <button key={`prediction_${i}`} onClick={() => handleSelect(p)}>
              <div className="prediction">
                <Picto iconKey={"place"} />
                {p.label || p.description}
              </div>
              {renderTag(p)}
            </button>
          ))}
        </div>
        {favorites && !inputRef.current?.value ? (
          <div className="favorite">
            <div>{t(`travelPlaner.Search.subTitle`)}</div>
            {favorites.map((f) => (
              <button key={f.key} onClick={() => handleSelectFavorite(f)}>
                <ResponsiveImage
                  requirements={requirements}
                  src={
                    f.image ||
                    `https://cdn.cibler.io/static/tca/v2/travelPlaner/${f.key}.jpg`
                  }
                  alt={f.label}
                  mobileWidth={375}
                  width={375}
                />
                <div className={`tag ${f.type}`}>
                  <Picto iconKey={f.type} />
                  {f.label}
                </div>
              </button>
            ))}
          </div>
        ) : null}
        <div
          nonce="__nonce__"
          style={{ display: "none" }}
          ref={ref => { voidRef.current = ref }}
        />
      </FilterLayout>
    </div>
  );
};
