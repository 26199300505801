import React, { useState, useEffect } from "react";
import { imageUrlFor } from "../../tools/sanity";
import { RequirementsProps } from "../../tools/context";

export interface InteractiveContentProps {
  requirements: RequirementsProps;
  isFullWidth?: boolean;
  padding?: string;
  margin?: string;
  h2?: string;
  Texte?: string;
  callsToAction?: CallToActionProps[];
  imageOverlay?: ImageOverlayProps;
  contentOrder: 'text-images' | 'images-text';
  displayType: 'flex' | 'grid';
  backgroundColor?: { hex: string };
  height?: string;
  className?: string;
  textBackgroundColor?: { hex: string };
  responsiveSettings?: {
    mobile?: ResponsiveSettingsProps;
    tablet?: ResponsiveSettingsProps;
    desktopL?: ResponsiveSettingsProps;
    desktopXL?: ResponsiveSettingsProps;
  };
}

interface ResponsiveSettingsProps {
  padding?: string;
  margin?: string;
  height?: string;
  fontSize?: string;
}

interface CallToActionProps {
  url?: string;
  text?: string;
  textColor?: { hex: string };
  backgroundColor?: { hex: string };
  borderColor?: { hex: string };
  borderWidth?: number;
  borderStyle?: 'solid' | 'dotted' | 'dashed' | 'double' | 'groove' | 'ridge' | 'inset' | 'outset';
  borderRadius?: number;
  hoverTextColor?: { hex: string };
  hoverBackgroundColor?: { hex: string };
  hoverBorderColor?: { hex: string };
}

interface Image {
  asset: {
    _ref: string;
  };
  hotspot?: {
    x: number;
    y: number;
  };
  alt?: string;
  width?: string;
  height?: string;
  backgroundColor?: { hex: string };
  backgroundUrl?: string;
}

interface OverlayItem {
  image: Image;
  positionX?: number;
  positionY?: number;
  zIndex?: number;
}

interface ImageOverlayProps {
  backgroundImage: Image;
  overlayItems: OverlayItem[];
}

const InteractiveContent: React.FC<InteractiveContentProps> = (props) => {
  const {
    isFullWidth,
    padding,
    margin,
    h2,
    Texte,
    callsToAction,
    imageOverlay,
    requirements,
    backgroundColor,
    height,
    textBackgroundColor,
  } = props;

  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('mobile');

  useEffect(() => {
    const mediaQueries = [
      { query: '(min-width: 1339px)', name: 'desktopXL' },
      { query: '(min-width: 1024px) and (max-width: 1439px)', name: 'desktopL' },
      { query: '(min-width: 768px) and (max-width: 1023px)', name: 'tablet' },
      { query: '(max-width: 767px)', name: 'mobile' },
    ];


    const mediaQueryLists = mediaQueries.map(({ query }) => window.matchMedia(query));

    const getCurrentBreakpoint = () => {
      const index = mediaQueryLists.findIndex(mql => mql.matches);
      return mediaQueries[index].name;
    };

    const handleResize = () => {
      setCurrentBreakpoint(getCurrentBreakpoint());
    };

    mediaQueryLists.forEach(mql => mql.addListener(handleResize));
    handleResize();

    return () => {
      mediaQueryLists.forEach(mql => mql.removeListener(handleResize));
    };
  }, []);

  const getResponsiveStyle = () => {
    const { responsiveSettings } = props;
    if (responsiveSettings && responsiveSettings[currentBreakpoint as keyof ResponsiveSettingsProps]) {
      return responsiveSettings[currentBreakpoint as keyof ResponsiveSettingsProps];
    }
    return {};
  };

  const renderHTML = (htmlString: string) => {
    return { __html: htmlString };
  };

  const TextContent = () => (
    <div
      className="InteractiveContent__text"
      style={{
        backgroundColor: textBackgroundColor?.hex
      }}
    >
      {Texte && <div className="description" dangerouslySetInnerHTML={renderHTML(Texte)} />}
      {callsToAction && callsToAction.length > 0 && (
        <div className="ctas_buttons">
          {callsToAction.map((cta, index) => (
            <a
              key={index}
              className="cta"
              href={cta.url}
              style={{
                color: cta.textColor?.hex,
                backgroundColor: cta.backgroundColor?.hex,
                borderColor: cta.borderColor?.hex,
                borderWidth: cta.borderWidth,
                borderStyle: cta.borderStyle,
                borderRadius: cta.borderRadius ? `${cta.borderRadius}` : undefined,
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = cta.hoverBackgroundColor?.hex || cta.backgroundColor?.hex;
                e.currentTarget.style.color = cta.hoverTextColor?.hex || cta.textColor?.hex;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = cta.backgroundColor?.hex;
                e.currentTarget.style.color = cta.textColor?.hex;
              }}
            >
              {cta.text}
            </a>
          ))}
        </div>
      )}
    </div>
  );

  const ImageContent = () => (
    imageOverlay && imageOverlay.backgroundImage && (
      <div className="InteractiveContent__images">
        <img
          src={imageUrlFor(imageOverlay.backgroundImage, requirements).url()}
          alt={imageOverlay.backgroundImage.alt}
          className="background_image"
          style={{
            width: imageOverlay.backgroundImage.width,
            height: imageOverlay.backgroundImage.height,
            backgroundColor: imageOverlay.backgroundImage.backgroundColor?.hex
          }}
        />
        {imageOverlay.overlayItems && imageOverlay.overlayItems.length > 0 && (
          imageOverlay.overlayItems.map((item, index) => (
            <img
              key={index}
              src={imageUrlFor(item.image, requirements).url()}
              alt={item.image.alt}
              /*style={{
                position: "absolute",
                zIndex: item.zIndex,
                top: item.positionY,
                left: item.positionX,
                width: item.image.width,
                height: item.image.height
              }}*/
              className="overlay_item"
            />
          ))
        )}
      </div>
    )
  );

  return (
    <div
      className={`InteractiveContent`}
      style={{
        padding: padding || '0',
        margin: isFullWidth ? '0' : (margin || ''),
        backgroundColor: backgroundColor?.hex,
        height: height,
        ...getResponsiveStyle()
      }}
    >
      <div className="InteractiveContent__text__container">
      {h2 && <div className="InteractiveContent__title" dangerouslySetInnerHTML={renderHTML(h2)} />}
      <TextContent />
      </div>

      <ImageContent />

    </div>
  );
};

export default InteractiveContent;
