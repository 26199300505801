import { Picto } from "../Picto";
import * as React from "react";
import { useTranslation } from "../../tools/i18n";
import { Fragment } from "react";
import { RequirementsProps } from "../../tools/context";

export interface ContactsProps {
  requirements: RequirementsProps;
  seller?: any;
  data?: any;
  hideShortcode: boolean;
  hideBookButton: boolean;
  hideMail?: boolean; 
  hidePhone?: boolean;
  hidePrice?: boolean;
}

const Contacts = ({
  requirements,
  seller,
  data,
  hideShortcode,
  hideBookButton,
  hideMail, 
  hidePhone,
  hidePrice,
}: ContactsProps) => {
  const { t } = useTranslation();
  let contact_content = [];

  const name = seller?.name;
  const email = seller?.contactEmail;
  const phone = seller?.contactPhone;

  const street = data?.street;
  const zipcode = data?.zipcode;
  const city = data?.city;
  const website = data?.website;
  const price_level = data?.price_level || 1;
  const opening_hours = data?.opening_hours
    ? JSON.parse(data?.opening_hours)
    : null;

  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  // adresse
  contact_content.push(
    <div
      key={"contact_address"}
      className="contact-element"
      nonce="__nonce__"
      style={{ alignItems: "flex-start" }}
    >
      <Picto iconKey={"location"} />
      <div>
        <p nonce="__nonce__" style={{ fontWeight: 700 }}>
          {name}
        </p>
        {street && zipcode && city ? (
          <Fragment>
            <p>{street}</p>
            <p>
              {zipcode} {city}
            </p>
          </Fragment>
        ) : (
          <p>{data.formattedAddress}</p>
        )}
      </div>
    </div>
  );

  // email
  if (email && !email.includes("@importsansemail") && !hideMail)
    contact_content.push(
      <div key={"contact_email"} className="contact-element">
        <Picto iconKey={"envelope"} />
        <p>{email}</p>
      </div>
    );

  // phone
  if (phone && !hidePhone)
    contact_content.push(
      <div key={"contact_phone"} className="contact-element">
        <Picto iconKey={"phone"} />
        <div>
          <p>{t("shop.informationsContact.reservation")}</p>
          <p>{phone}</p>
        </div>
      </div>
    );

  // email
  if (website)
    contact_content.push(
      <div key={"contact_website"} className="contact-element">
        <Picto iconKey={"navigation"} />
        <p>
          <a href={website} target="_blank" rel="noreferrer">
            {website}
          </a>
        </p>
      </div>
    );

  // price level
  if (price_level && requirements?.config?.key !== "correze" && !hidePrice) {
    contact_content.push(
      <div key={"contact_price_level"} className="contact-element">
        <Picto iconKey="travel-expensive-new-ui-v2" height="23" width="23" />
        <div>
          <p>{t("shop.informationsContact.priceIndicator")}</p>
          <div className="price-level">
            {Array(Number(price_level))
              .fill(0)
              .map((_, index) => (
                <Picto
                  key={index}
                  iconKey="euro"
                  height="14"
                  width="14"
                  className="euro-sign"
                />
              ))}
          </div>
        </div>
      </div>
    );
  }

  // opening hours
  if (opening_hours)
    contact_content.push(
      <div key={"contact_opening_hours"} className="contact-element">
        <Picto iconKey={"clock"} />
        <div>
          <p>{t("shop.informationsContact.openingHours")}</p>
          {opening_hours.map((day: string, index: number) => (
            <p key={index}>{capitalize(day)}</p>
          ))}
        </div>
      </div>
    );

  return (
    <div className="contact">
      <p className="title">{t("shop.informationsContact.title")}</p>
      {contact_content}
      <div key={"contact_website"} className="contact-element">
        {(!data.seller_type || data.seller_type === "store") && !hideShortcode && (
          <p>
            <span nonce="__nonce__" style={{ fontWeight: "bold" }}>
              {t("shop.informationsContact.partnerCode")} :{" "}
            </span>
            {seller.shortcode}
          </p>
        )}
      </div>
      {data.booking_url && !hideBookButton ? (
        <a href={data.booking_url} className="Button client">
          {t("shop.informationsContact.book")}
        </a>
      ) : null}
    </div>
  );
};

export default Contacts;
