import * as React from 'react';
import { RequirementsProps } from '../../tools/context';
import { imageUrlFor } from '../../tools/sanity';

export interface PartnerProps {
  _key: string;
  alt: string;
  image: any;
  link?: string;
}

export interface PartnerListProps {
  label: string;
  list: PartnerProps[];
  requirements: RequirementsProps;
}

const PartnerList = (props: PartnerListProps) => {
  const { label, list, requirements } = props;

  return (
    <div className="partnerList">
      <div className="header">{label}</div>
      <div className="scrollBlock">
        {list &&
          list.map((c) => (
            <a className="block" key={c._key} href={c.link} target="_blank" rel="noreferrer">
              <img
                src={typeof c.image === 'object' ? imageUrlFor(c.image, requirements).url() : ''}
                alt={c.alt}
              />
            </a>
          ))}
      </div>
    </div>
  );
};

export default PartnerList;
