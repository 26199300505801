import React, { useEffect, useState } from 'react';
import { RequirementsProps } from '../../tools/context';
import { getImageSrc, mapApiWeatherCodeToWeatherCode, WeatherResources } from './constants';

export interface MeteoDataProps {
  averageAirPressureAtSeaLevelInHectoPascal: number;
  averageRelativeHumidityInPercent: number;
  averageWindSpeedInKilometerPerHour2: number;
  averageWindSpeedInKilometerPerHour10: number;
  date: string;
  dominantWindDirection: string;
  dominantWindDirectionInDegree: number;
  effectiveCloudCoverInPercent: number;
  evaporationMakkinkInMillimeter: number;
  globalRadiationInJoulePerSquareCentimeter: number;
  groundFrostProbabilityInPercent: number;
  maxAirTemperatureInCelsius: number;
  maxPrecipitationAmountInMillimeter: number;
  maxWindGustInKilometerPerHour2: number;
  maxWindGustInKilometerPerHour10: number;
  minAirTemperatureInCelsius: number;
  minAirTemperatureNearGroundInCelsius: number;
  moonPhase: number;
  mostSignificantWeatherCode: number;
  precipitationAmountInMillimeter: number;
  precipitationProbabilityInPercent: number;
  saturationDeficitInHectoPascal: number;
  sunrise: number;
  sunset: number;
  sunshineDurationInHours: number;
  uvIndexWithClouds: number;
}

export interface MeteoProps {
  lon: number | string;
  lat: number | string;
  requirements: RequirementsProps;
  siteConfiguration: any;
}

const Renderer = ({ lon, lat, requirements, siteConfiguration }: MeteoProps) => {
  const [data, setData] = useState<MeteoDataProps>(null);

  const formatDate = (date: Date) =>
    date.toISOString().split('T')[0] + 'T' + date.toTimeString().split(' ')[0];

  const getData = async () => {
    const from = new Date();
    from.setHours(10);
    const to = new Date();
    to.setHours(12);
    const queries = {
      latitude: lat,
      longitude: lon,
      'timeSteps[]': 'timeStep24',
      from: formatDate(from),
      until: formatDate(to),
    };
    const lendpoint = requirements.endpoint;
    const search = Object.keys(queries)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(queries[k])}`)
      .join('&');
    const res = await fetch(`${lendpoint}/ext/forecasts?${search}`);

    if (!res?.ok) throw new Error(`HTTP error! status: ${res.status}`);

    try {
      const meteo = await res.json();
      setData(meteo.timeStep24[0]);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  useEffect(() => {
    if (!lon || !lat) return;

    getData().catch(console.error);
  }, []);

  if (!data || !siteConfiguration.meteoEnabled) return null;

  const weatherCode = data.mostSignificantWeatherCode;
  const realWeatherCode = mapApiWeatherCodeToWeatherCode(weatherCode);

  return (
    <div className="meteo">
      <div className="weather">{WeatherResources[weatherCode]}</div>
      <div className="temperature">
        {data.minAirTemperatureInCelsius}C°/{data.maxAirTemperatureInCelsius}C°
        <img
          className="logo"
          src={`https://cdn.cibler.io/static/tca/v2/meteo/pleinchampLogo.svg`}
          alt="Logo Pleinchamp"
        />
      </div>
      <img
        className="weatherImg"
        src={`https://cdn.cibler.io/static/tca/v2/meteo/${getImageSrc(realWeatherCode)}`}
        alt={WeatherResources[weatherCode]}
      />
    </div>
  );
};

const Meteo = { Renderer };

export default Meteo;
export { Renderer };
