import React from 'react';
import { PictoProps } from './index';

 const MagnifyingGlass = ({ width, height, fill }: PictoProps) => (
   <svg width={width ||"22"} height={ height|| "26"} viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g clip-path="url(#clip0_2307_1679)">
       <path
         d="M17.3514 4.12025C14.3352 -0.243505 8.37641 -1.31086 4.07038 1.73731C-0.240557 4.79046 -1.29499 10.8173 1.71628 15.1811C4.40387 19.0782 9.44065 20.3342 13.5554 18.3931L18.1214 25.0057C18.6412 25.7603 19.6712 25.949 20.4215 25.4178C21.167 24.8866 21.3534 23.849 20.8286 23.0895L16.2626 16.4768C19.4799 13.23 20.039 8.01239 17.3465 4.12025H17.3514ZM13.1091 14.8236C10.2891 16.8193 6.39505 16.1194 4.42349 13.2648C2.45194 10.4102 3.14345 6.46844 5.95856 4.47273C8.77857 2.47702 12.6726 3.17701 14.6442 6.02661C16.6157 8.88117 15.9242 12.823 13.1091 14.8187V14.8236Z"
         fill={fill || "#8C7215"} />
     </g>
     <defs>
       <clipPath id="clip0_2307_1679">
         <rect width="21.1271" height="25.72" fill="white" />
       </clipPath>
     </defs>
   </svg>

 )

export default MagnifyingGlass;
