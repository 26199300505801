import React, { useState, useEffect } from "react";
import {Picto} from "../Picto";
import StaticCarousel from "../Carousel/StaticCarousel";
import { fetchArticlesData } from "../../tools/apiTools";
import { useTranslation } from 'react-i18next';

interface Article {
    title: string;
    url: string;
    image_url: string;
    category: string;
    location: string;
}

interface ArticlesProps {
    requirements: any;
    cityKey: string;
    postalCode?: string;
}

const removeDuplicateArticles = (articles: Article[]): Article[] => {
    const uniqueTitles = new Set();
    return articles.filter(article => {
        if (uniqueTitles.has(article.title)) {
            return false;
        }
        uniqueTitles.add(article.title);
        return true;
    });
};

const filterArticlesByUrl = (articles: Article[], lang: string): Article[] => {
    return articles.filter(article => {
        const baseUrl = `https://www.france.fr/${lang}`;
        return article.url.startsWith(baseUrl);
    });
};

const Articles: React.FC<ArticlesProps> = ({ requirements, cityKey, postalCode }) => {
    const { t, i18n } = useTranslation();
    const [articles, setArticles] = useState<Article[]>([]);
    const lang = i18n.language;

    const formatCityKey = (key: string): string => {
        return key.split(',')[0].trim().toLowerCase();
    };

    useEffect(() => {
        const formattedCityKey = formatCityKey(cityKey);
        
        fetchArticlesData(formattedCityKey, lang, postalCode)
            .then((data) => {
                console.log('data', data);
                if (data) {
                    const mappedArticles = data.map((article, index) => ({
                        key: `article_${index}`,
                        title: article.title,
                        image: article.image_url,
                        noNote: true,
                        url: article.url,
                        place: article.location,
                    }));
                    console.log('mappedArticles', mappedArticles);
                    const uniqueArticles = removeDuplicateArticles(mappedArticles);
                    const filteredArticles = filterArticlesByUrl(uniqueArticles, lang);
                    setArticles(filteredArticles);
                }
            })
            .catch((e) => console.error('Erreur lors du chargement des articles:', e));
    }, [cityKey, lang, postalCode]);

    return (
        <>
            {articles.length > 0 ? (
                <div className="Articles">
                    <div className="articles-header">
                        <Picto iconKey={"voyages"} width="28" height="28" />
                        <span>{t('travelPlaner.Travel.articles.title')}</span>
                    </div>
                    <StaticCarousel
                        label=""
                        subLabel=""
                        slides={articles}
                        requirements={requirements}
                    />
                </div>
            ) : null}
        </>
    );
};

export default Articles;
