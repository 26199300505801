const config = {
  key: 'charentes',
  canonicalUrl: 'https://www.infiniment-charentes.com',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 703,
  icon: 'charentes.png',
  headerLogo: 'charentes-logo-white.svg',
  footerLogo: 'charentes-logo-black.svg',
  axeptioClientId: '675bf5b477481d7d52d7879c',
  axeptioCookiesVersion: 'infiniment-charentes',
  clientUrl: 'https://www.infiniment-charentes.com/',
  proUrl: 'https://www.infiniment-charentes.com/',
  sanity: {
    base: {
      projectId: '8xljztwh',
      dataset: 'production',
      apiVersion: '2022-12-01',
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        'sk8zcdVGgjyK6D1k6vycBFLVKlDTsiChfBmbw6YSbVZ3F4U0dAnuUOMcx09CdeAzJKCU7kCi8ansMaTeOXwmqfShuHLBgzfmunUZNVWIvkYr8G5pzleNHFQkH5lmvosBhQhfcwdWmZA8ZF1EecU3C2JWmg10Fj4PUFOa6icV2KRmqULyuFoZ',
    },
  },
  dev: {
    clientUrl: 'https://www.infiniment-charentes.com/',
    proUrl: 'https://www.infiniment-charentes.com/',
  },
  travelForceLogin: false,
  activeFloatingSearchBar: true,
  apidae: {
    projectId: 5136,
    apiKey: 'gidi9neo',
  },
  tracking:{
    isMatomoEnabled: true,
    containerId: "RRF4yvvV",
  },
  enableWioScript : false
};

export default config;
