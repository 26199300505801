import React, { useEffect } from 'react';

interface MatomoScriptProps {
  containerId: string;
}

const MatomoScript: React.FC<MatomoScriptProps> = ({ containerId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/infinimentcharentes.matomo.cloud/container_${containerId}.js'; s.parentNode.insertBefore(g,s);
  })();
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default MatomoScript;
