import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { OverLayParameter } from '../../../App';
import { RequirementsProps } from '../../../tools/context';
import { useTranslation } from '../../../tools/i18n';
import { getCookies, getQueryString, isDebugMode, replaceQueryString } from '../../../tools/tools';
import { Button } from '../../Button';
import ConfirmationPopup from './ConfirmationPopup';

export interface CouponsPromoProps {
  coupons: any;
  device?: 'mobile' | 'desktop';
  requirements: RequirementsProps;
  setOverlay: (p: OverLayParameter) => void;
}

const CouponsPromo = ({ coupons, device, requirements, setOverlay }: CouponsPromoProps) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<'confirmation' | 'howto'>(null);
  const [, setSelected] = useState<any>();

  const assignCoupon = (couponId, loginToken) => {
    const endpoint = requirements.endpoint;
    const headers = new Headers();
    headers.append('CIBLER_AUTH', loginToken);
    fetch(`${endpoint}/api/prizes/specific/${couponId}`, { headers });
    setShowPopup('confirmation');
  };

  useEffect(() => {
    const cookies = getCookies();
    const couponId = getQueryString('promo');
    if (couponId && cookies.loginToken) {
      replaceQueryString('promo', null);
      assignCoupon(couponId, cookies.loginToken);
    }
  }, []);

  if (!coupons || !coupons.length) return null;

  const handleCouponClick = (coupon) => {
    const cookies = getCookies();
    if (!cookies.loginToken) {
      setSelected(coupon);
      setOverlay({ key: 'loginPopup', context: { queries: { promo: coupon.id } } });
      return;
    }

    assignCoupon(coupon.id, cookies.loginToken);
  };

  const today = new Date();
  const coupons_elements = [];
  coupons.forEach((coup) => {
    const expirationDate = coup.expirationDate ? new Date(coup.expirationDate) : null;
    if (expirationDate && today > expirationDate) return;

    const condition = coup.giftData.find((d) => d.key === 'conditions');
    const debugTxt = isDebugMode() ? JSON.stringify(coup) : null;
    const content = (
      <button className="advantage Button" key={coup.id} onClick={() => handleCouponClick(coup)}>
        <p className="advantage_title">{coup.description}</p>
        {condition && (
          <i>
            <div>{condition.value}</div>
          </i>
        )}
        {expirationDate && (
          <div>
            {t('shop.advantages.discount.tracked.until')}{' '}
            {moment(expirationDate).format('DD/MM/YYYY')}
          </div>
        )}
        <div className="add">{t('shop.advantages.discount.tracked.addAdvantages')}</div>
      </button>
    );

    coupons_elements.push(content);
  });

  if (coupons_elements.length === 0) return null;

  const popupList = {
    confirmation: (
      <ConfirmationPopup
        confirmation
        close={() => setShowPopup(null)}
        requirements={requirements}
      />
    ),
    howto: <ConfirmationPopup close={() => setShowPopup(null)} requirements={requirements} />,
  };

  return (
    <>
      <div className={`advantages ${device || ''}`}>
        <p className="advantages_title">{t('shop.advantages.discount.tracked.selectAdvantages')}</p>
        {coupons_elements}
        <Button theme={'client'} onClick={() => setShowPopup('howto')}>
          {t('shop.advantages.discount.tracked.howto')}
        </Button>
      </div>
      {popupList[showPopup]}
    </>
  );
};

export default CouponsPromo;
