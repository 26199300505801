import React from 'react';
import { PAGE_ROUTES } from '../../../../App';
import { Header } from '../../../../components';
import { pictos } from '../../../../components/Picto';
import { AuthConfigProps, RequirementsProps } from '../../../../tools/context';
import { useTranslation } from '../../../../tools/i18n';
import AccountPanel from '../../components/AccountPanel';
import PageHeader from '../../components/PageHeader';
import AccountPage from '../../pages/auth/Account';
import FavoritesPage from '../../pages/auth/Favorites';
import NewsletterPage from '../../pages/auth/Newsletter';

interface AuthStackProps {
  authConfig: AuthConfigProps;
  requirements: RequirementsProps;
  navigation: any;
  headerButtons: any;
  headerType?: 'classic' | 'modern';
  footerType?: 'classic' | 'modern';
  header?: any;
}

interface Page {
  titleKey: string;
  headerIcon: keyof typeof pictos;
  path: string;
  component: React.FC<any>;
}

const AuthStack = ({
  authConfig,
  requirements,
  headerType = 'classic',
  ...rest
}: AuthStackProps) => {
  const { t } = useTranslation();

  const pages: Page[] = [
    {
      titleKey: 'personalInfos',
      headerIcon: 'userV2',
      path: PAGE_ROUTES.AUTH.ACCOUNT,
      component: AccountPage,
    },
    {
      titleKey: 'favorites',
      headerIcon: 'heartV2',
      path: PAGE_ROUTES.AUTH.FAVORITES,
      component: FavoritesPage,
    },
    {
      titleKey: 'newsletter',
      headerIcon: 'envelopeV2',
      path: PAGE_ROUTES.AUTH.NEWSLETTER,
      component: NewsletterPage,
    },
  ];

  const Page = pages.find((page) => page.path === window.location.pathname);

  if (!Page) {
    window.location.href = PAGE_ROUTES.AUTH.ACCOUNT;
  }

  const PageComponent = Page.component;

  return (
    <div className="AuthStack">
      <Header {...rest} requirements={requirements} headerType={headerType || 'classic'} />
      <div className="AuthStack__wrapper">
        <AccountPanel />
        <div className="AuthStack__page">
          <PageHeader
            title={t(`ssrComponents.auth.pages.${Page.titleKey}.title`)}
            icon={Page.headerIcon}
          />
          <PageComponent requirements={requirements} {...rest} />
        </div>
      </div>
    </div>
  );
};

export default AuthStack;
