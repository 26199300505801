import * as React from 'react';
import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { v4 as uuidv4 } from 'uuid';
import FavoriteButton from '../../features/favorites/components/Button';
import { RequirementsProps } from '../../tools/context';
import { StickyHeaderManager } from '../StickyHeaderManager';
import { StickyItem } from '../StickyItem';

export interface EngineProps {
  requirements: RequirementsProps;
  engine_id: string;
  initialPath?: string;
  query?: string;
  chatId?: string;
}

const Engine: React.FC<EngineProps> = ({
  requirements,
  engine_id,
  initialPath,
  query = 'Hello',
  chatId,
}) => {
  const titleContainerRef = useRef(null);
  const titleTargetRef = useRef(null);
  const titleObserverRef = useRef(null);
  const titleIntervalRef = useRef(null);

  const favoriteButtonContainerRef = useRef(null);
  const favoriteButtonTargetRef = useRef(null);
  const favoriteButtonObserverRef = useRef(null);
  const favoriteButtonIntervalRef = useRef(null);

  useEffect(() => {
    if (window.ResponseEngine) {
      window.ResponseEngine.unmount?.();
      window.ResponseEngine.mount({
        elementId: 'response-engine-root',
        initialPath:
          initialPath || `/engine/${engine_id}/chat?chat_id=${chatId || uuidv4()}&query=${query}`,
      });
    }
  }, [initialPath, engine_id, query, chatId]);

  useEffect(() => {
    const renderFavoriteButton = (element: HTMLElement) => {
      const favoriteButtonElement = document.createElement('div');
      element.parentNode.insertBefore(favoriteButtonElement, element.nextSibling.nextSibling);

      const params = window.location.hash
        ?.split('?')?.[1]
        ?.split('&')
        ?.map((param) => {
          const [key, value] = param.split('=');
          return { [key]: decodeURIComponent(value) };
        })
        ?.reduce((acc, curr) => ({ ...acc, ...curr }), {});

      const chatId = params.chat_id || '';
      const query = params.query || '';

      const root = createRoot(favoriteButtonElement);
      root.render(
        <StickyHeaderManager>
          <StickyItem position="right">
            <FavoriteButton
              favorite={{
                type: 'engine',
                data: {
                  chat_id: chatId,
                  query,
                },
              }}
            />
          </StickyItem>
        </StickyHeaderManager>
      );

      return root;
    };

    const checkForTargetElement = () => {
      const targetElement = document.getElementById('first-thread');
      if (targetElement && targetElement.firstElementChild) {
        clearInterval(favoriteButtonIntervalRef.current);
        favoriteButtonTargetRef.current = targetElement.firstElementChild;
        const root = renderFavoriteButton(targetElement.firstElementChild as HTMLElement);

        favoriteButtonObserverRef.current = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
              if (Array.from(mutation.removedNodes).includes(favoriteButtonContainerRef.current)) {
                root.unmount();
                favoriteButtonObserverRef.current.disconnect();
                favoriteButtonIntervalRef.current = setInterval(checkForTargetElement, 1000);
              }
            }
          });
        });

        favoriteButtonObserverRef.current.observe(targetElement, {
          childList: true,
          subtree: true,
        });
      }
    };

    favoriteButtonIntervalRef.current = setInterval(checkForTargetElement, 1000);

    return () => {
      clearInterval(favoriteButtonIntervalRef.current);
      if (favoriteButtonObserverRef.current) {
        favoriteButtonObserverRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const renderTitle = (element: HTMLElement) => {
      const titleElement = document.createElement('div');
      element.parentNode.replaceChild(titleElement, element);

      const root = createRoot(titleElement);
      root.render(
        <StickyHeaderManager>
          <StickyItem>
            <h1>{element.innerText}</h1>
          </StickyItem>
        </StickyHeaderManager>
      );

      return root;
    };

    const checkForTargetElement = () => {
      const targetElement = document.getElementById('first-thread-question');
      if (targetElement && targetElement.firstElementChild) {
        clearInterval(titleIntervalRef.current);
        titleTargetRef.current = targetElement.firstElementChild;
        const root = renderTitle(targetElement.firstElementChild as HTMLElement);

        titleObserverRef.current = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
              if (Array.from(mutation.removedNodes).includes(titleContainerRef.current)) {
                root.unmount();
                titleObserverRef.current.disconnect();
                titleIntervalRef.current = setInterval(checkForTargetElement, 1000);
              }
            }
          });
        });

        titleObserverRef.current.observe(targetElement, { childList: true, subtree: true });
      }
    };

    titleIntervalRef.current = setInterval(checkForTargetElement, 1000);

    return () => {
      clearInterval(titleIntervalRef.current);
      if (titleObserverRef.current) {
        titleObserverRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="Engine" id={engine_id}>
      <div id="response-engine-root"></div>
    </div>
  );
};

export default Engine;
