export default {
  key: 'tca',
  canonicalUrl: 'https://www.tourismebyca.fr',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 161,
  icon: 'TCA.png',
  headerLogo: 'tca.png',
  footerLogo: 'tca.png',
  axeptioClientId: '63c15e09e908b5eb5c44476a',
  axeptioCookiesVersion: 'tourismebyca-fr',
  clientUrl: 'https://client.tourismebyca.fr/',
  proUrl: 'https://pro.tourismebyca.fr/',
  sanity: {
    base: {
      projectId: 'oh86a0j4',
      dataset: 'production',
      apiVersion: '2022-12-01',
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        'skpowEj6lcM0OyXzoOMe1sMaBZTUmb4jwyOoQKf0F6gp9wvxlwvkycNhlLQzOhSysh9ufKWHoqzfsw5Ljfq9VziS5GQaMJYP9GO2HCjzVbRdbDn3meCv8RFOc9DsyRXckWJbYwSQpvzhhwiJjttmsLRwEdFxIBoTDxF2d2klGzO9YGU6DOlH',
    },
  },
  dev: {
    clientUrl: 'https://dev.tourismebyca.fr/',
    proUrl: 'https://dev.tourismebyca.fr/',
  },
  travelForceLogin: false,
};
