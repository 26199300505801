import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { contactFormSchema, ContactFormData, EmailData } from './schema';
import { sendEmail } from '../../services/emailService';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

interface SubjectOption {
  _key: string;
  value: string;
  label: string;
  email_to: string;
}

interface FormConfigProps {
  submitButtonText: string;
  placeholders: {
    first_name: string;
    last_name: string;
    email: string;
    message: string;
    subject: string;
  };
  subjectOptions: SubjectOption[];
  recipientEmails: string[];
  optinConfig: {
    optinText: string;
    isRequired: boolean;
  };
}

interface ContactFormProps {
  formConfig: FormConfigProps;
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
  const {
    formConfig,
  } = props;

  console.log('Form config:', formConfig);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset
  } = useForm<ContactFormData>({
    resolver: zodResolver(contactFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      partner: 'default',
    }
  });

  const selectedSubject = watch('subject');

  useEffect(() => {
    if (selectedSubject) {
      const subject = formConfig.subjectOptions.find(
        option => option.value === selectedSubject
      );
      setValue('email_to', subject?.email_to || formConfig.recipientEmails[0]);
    }
  }, [selectedSubject, formConfig.subjectOptions, setValue]);

  const onSubmit = async (data: ContactFormData) => {
    
    setIsSubmitting(true);
    
    try {
      const { optin, ...formData } = data;
      const selectedSubject = formConfig.subjectOptions.find(
        option => option.value === data.subject
      );
      
      const enrichedData = {
        ...formData,
        email_to: selectedSubject?.email_to,
        partner: 'default',
        optin: data.optin
      } as EmailData;

      await sendEmail(enrichedData);
      
      Swal.fire({
        title: 'Succès !',
        text: 'Votre message a bien été envoyé',
        icon: 'success'
      });

      reset();
    } catch (error) {
      console.error('Erreur:', error);
      Swal.fire({
        title: 'Erreur',
        text: 'Une erreur est survenue lors de l\'envoi',
        icon: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!formConfig) return <div>Chargement...</div>;

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="w-full max-w-2xl">
        <h2 className="text-2xl font-bold">Contactez-nous</h2>
        <p className="text-sm text-gray-600 mb-6">
          Nous vous répondrons dans les plus brefs délais.
        </p>

        <form 
          onSubmit={(e) => {
            handleSubmit(onSubmit)(e);
          }} 
          className="space-y-6"
          noValidate
        >
          <div className="grid grid-cols-4 gap-4 items-center">
            <label className=" text-gray-500 font-bold" htmlFor="first_name">
              Prénom<span className="text-red-500">*</span>
            </label>
            <div className="col-span-3">
              <input
                id="first_name"
                type="text"
                {...register('first_name')}
                placeholder={formConfig.placeholders.first_name}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
              />
              {errors.first_name && (
                <span className="text-red-500 text-sm">{errors.first_name.message}</span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 items-center">
            <label className=" text-gray-500 font-bold" htmlFor="last_name">
              Nom<span className="text-red-500">*</span>
            </label>
            <div className="col-span-3">
              <input
                id="last_name"
                type="text"
                {...register('last_name')}
                placeholder={formConfig.placeholders.last_name}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
              />
              {errors.last_name && (
                <span className="text-red-500 text-sm">{errors.last_name.message}</span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 items-center">
            <label className="text-gray-500 font-bold col-span-1" htmlFor="email">
              Email<span className="text-red-500">*</span>
            </label>
            <div className="col-span-3">
              <input
                id="email"
                type="email"
                {...register('email')}
                placeholder={formConfig.placeholders.email}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
              />
              {errors.email && (
                <span className="text-red-500 text-sm">{errors.email.message}</span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4 items-center">
            <label className=" text-gray-500 font-bold" htmlFor="subject">
              Sujet<span className="text-red-500">*</span>
            </label>
            <div className="col-span-3">
              <select
                id="subject"
                {...register('subject')}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
              >
                <option value="">{formConfig.placeholders.subject}</option>
                {formConfig.subjectOptions.map((option) => (
                  <option key={option._key} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.subject && (
                <span className="text-red-500 text-sm">{errors.subject.message}</span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4 items-center">
            <label className=" text-gray-500 font-bold" htmlFor="message">
              Message<span className="text-red-500">*</span>
            </label>
            <div className="col-span-3">
              <textarea
                id="message"
                {...register('message')}
                placeholder={formConfig.placeholders.message}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
                rows={4}
              />
              {errors.message && (
                <span className="text-red-500 text-sm">{errors.message.message}</span>
              )}
            </div>
          </div>

          <div className="gap-4 items-center">
            <label className=" text-gray-500 font-bold flex items-start">
              <input
                type="checkbox"
                id="optin"
                {...register('optin', {
                  required: formConfig.optinConfig.isRequired
                })}
                className="mr-2 mt-1 leading-tight"
              />
              <span className="text-xs ml-1 text-left">{formConfig.optinConfig.optinText}</span>
            </label>
            {errors.optin && (
              <span className="text-red-500 text-xs">
                Veuillez accepter les conditions pour continuer
              </span>
            )}
          </div>

          <div className="grid  gap-4 items-center">
            <div className="col-span-2 flex justify-center">
              <button
                type="submit"
                className="shadow submit-button hover:bg-green-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              >
                
                {isSubmitting ? 'Envoi...' : formConfig.submitButtonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}; 

export default ContactForm;