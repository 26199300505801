import React from 'react';
import { useTranslation } from '../../../tools/i18n';
import ButtonV2, { ButtonV2Props } from '../../ButtonV2';

interface ContactButtonProps extends Partial<ButtonV2Props> {
  valueType: 'phone' | 'email';
  value?: string;
}

const ContactButton: React.FC<ContactButtonProps> = ({ valueType, value }) => {
  const { t } = useTranslation();

  if (!!!value) return null;

  return (
    <ButtonV2
      text={t('ssrComponents.buttons.contact.text')}
      leftIcon={valueType === 'phone' ? 'phoneV2' : 'envelopeV2'}
      align="left"
      link={`${valueType === 'phone' ? 'tel' : 'mailto'}:${value}`}
    />
  );
};

export default ContactButton;
