import React, {useEffect, useState} from "react";
import {RequirementsProps} from "../../tools/context";
import * as Configurations from "../../configurations";
import {makeAnAlgoliaSearch} from "../../tools/searchAPI";
import TravelPlanerHubHero from "./components/Hero";
import TravelPlanerHubComplexSearch, {SearchDataType} from "./components/ComplexSearch";
import TravelPlanerHubJourneysList from "./components/JourneysList";
import TravelPlanerHubFiltersAndSort, {JourneysSortsType} from "./components/FiltersAndSort";
import {useTranslation} from "../../tools/i18n";
import TravelPlanerHubCategoriesFilters, {JourneysCategory} from "./components/CategoriesFilters";
import {getSanityClient} from "../../tools/sanity";
import {TravelPlannerResultsConfigType} from "../TravelPlanerResults/types";
import {
  TravelPlannerConfigType,
  TravelPlannerInterestsType,
  TravelPlannerNewInterestsType
} from "../TravelPlaner/types";

export type JourneyType = {
  budget: string;
  citykey: string;
  composition: string;
  creation_date: string;
  description: string;
  dislikes: number;
  id: number;
  inputs: string;
  interests: {
    [key: string]: 1 | 2 | 3
  }
  lat: number;
  likes: number;
  lon: number;
  objectID: string | number;
  radius: number;
  search: string;
  title: string;
  triplength: number;
  thumbnailurl: string | null;
};

export interface TravelPlannerSanityProps {
  path: {
    current: string
  }
  content: [{
    config: TravelPlannerConfigType;
  }]
}

export interface TravelPlannerResultsSanityProps {
  path: {
    current: string
  }
  content: [{
    config: TravelPlannerResultsConfigType;
  }]
}

export interface TravelPlanerHubProps {
  requirements: RequirementsProps
  componentStates: {
    journeys: {
      data: {
        hits: JourneyType[]
      }
    }
    travelPlanner: TravelPlannerSanityProps;
    travelPlannerResults: TravelPlannerResultsSanityProps;
  };
  agentKey: string;
  hideAgentKeyCss?: boolean;
}

const Renderer = (props: TravelPlanerHubProps) => {
  const {t, i18n} = useTranslation();
  const {requirements, componentStates, agentKey, hideAgentKeyCss} = props;
  const {journeys: defaultJourneys, travelPlanner, travelPlannerResults} = componentStates;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [journeys, setJourneys] = useState<JourneyType[]>(
    defaultJourneys?.data?.hits || []
  );
  const [searchData, setSearchData] = useState<SearchDataType>();
  const [sortBy, setSortBy] = useState<JourneysSortsType>('mostPopular');
  const [localGuides, setLocalGuides] = useState<boolean>(false);
  const [categories, setCategories] = useState<JourneysCategory[]>(() => {
    const step = travelPlanner.content?.[0]?.config?.steps?.find(s => (
      s.key === 'interests' || s.key === 'newInterests'
    )) as TravelPlannerInterestsType | TravelPlannerNewInterestsType;
    if (!step?.list?.length) return [];

    const keys = step.key === 'newInterests'
      ? (step as TravelPlannerNewInterestsType).list.map(p => p.key)
      : (step as TravelPlannerInterestsType).list;

    return keys
      .map(k => ({key: k, label: t(`travelPlaner.Interests.keys.${k}`), selected: false}))
      .sort((a, b) => a.label.localeCompare(b.label));
  })

  useEffect(() => {
    // Use the agentKey to have different configurations for the same partner
    if (agentKey?.length) {
      // Add the configuration for the agent
      requirements.config = Configurations[`${requirements.partner}_${agentKey}`] || requirements.config;

      // Add custom CSS
      if (!hideAgentKeyCss) {
        const head = document.getElementsByTagName('head')[0];
        const oldLink = document.getElementById(`customCSS_${requirements.config.key}`);
        const partnerDefaultStyle = document.getElementById('customCSS');
        if (partnerDefaultStyle) {
          partnerDefaultStyle.remove();
        }
        if (!oldLink) {
          const link = document.createElement('link');
          link.id = `customCSS_${requirements.config.key}}`;
          link.rel = 'stylesheet';
          link.type = 'text/css';
          link.href = `//${window.location.host}/custom/${requirements.config.key}.css`;
          link.media = 'all';
          head.appendChild(link);
        }
      }

      if (!i18n.language.includes(agentKey)) {
        const newLanguage = `${i18n.language}_${agentKey}`;
        i18n
          .changeLanguage(newLanguage, (err, t) => {
            if (err)
              return console.error('something went wrong loading', err);
          })
          .catch(e => console.error("Error changing language", e));
      }
    }
  }, [agentKey, requirements, hideAgentKeyCss, i18n]);

  const searchJourneys = (
    searchData: SearchDataType,
    sortBy: JourneysSortsType,
    localGuides: boolean,
    categories: JourneysCategory[]
  ) => {
    if (isLoading) return;
    setIsLoading(true);

    let stringFilter = searchData?.tripLength ? `triplength:${searchData.tripLength}` : "";

    if (localGuides) {
      stringFilter += stringFilter.length ? " AND " : "";
      stringFilter += "localGuides:true";
    }

    const selectedCategories = categories.filter(c => c.selected);
    if (selectedCategories.length) {
      stringFilter += stringFilter.length ? " AND " : "";
      stringFilter += selectedCategories.map(c => `interests.${c.key}:3`).join(' AND ');
    }

    const indexes = {
      mostPopular: "journeys",
      bestGrade: "journeys_replica_reviewscore",
    };
    const customIndex = indexes[sortBy] || indexes.mostPopular;

    makeAnAlgoliaSearch(
      { index: customIndex, query: searchData?.city ? searchData.city.city : "" },
      requirements,
      [],
      stringFilter,
    )
      .then(d => setJourneys(d.data.hits))
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleSearch = (searchData: SearchDataType) => {
    setSearchData(searchData);
    searchJourneys(searchData, sortBy, localGuides, categories);
  }

  const handleChangeSortBy = (newSortBy: JourneysSortsType) => {
    setSortBy(newSortBy);
    searchJourneys(searchData, newSortBy, localGuides, categories);
  }

  const handleChangeLocalGuides = (newLocalGuides: boolean) => {
    setLocalGuides(newLocalGuides);
    searchJourneys(searchData, sortBy, newLocalGuides, categories);
  }

  const handleChangeCategories = (newCategories: JourneysCategory[]) => {
    setCategories(newCategories);
    searchJourneys(searchData, sortBy, localGuides, newCategories);
  }

  return (
    <div className="TravelPlanerHub">
      <TravelPlanerHubHero
        title={t('travelPlaner.Hub.Hero.title')}
        subtitle={t('travelPlaner.Hub.Hero.subtitle')}
      />
      <TravelPlanerHubComplexSearch
        travelPlanner={travelPlanner}
        requirements={requirements}
        loading={isLoading}
        onSearch={handleSearch}
      />
      <TravelPlanerHubFiltersAndSort
        sortBy={sortBy}
        onChangeSortBy={handleChangeSortBy}
        localGuides={localGuides}
        onChangeLocalGuides={handleChangeLocalGuides}
      />
      <TravelPlanerHubCategoriesFilters
        categories={categories}
        onChangeCategories={handleChangeCategories}
      />
      <TravelPlanerHubJourneysList
        travelPlannerResults={travelPlannerResults}
        requirements={requirements}
        loading={isLoading}
        journeys={journeys}
      />
    </div>
  );
}

const preloader = async (
  _data: any,
  requirements: RequirementsProps,
  storybookData?: {
    travelPlanner: TravelPlannerSanityProps,
    travelPlannerResults: TravelPlannerResultsSanityProps,
  },
) => {
  if (!requirements) return null;

  let travelPlanner = storybookData?.travelPlanner;
  let travelPlannerResults = storybookData?.travelPlannerResults;

  if (!storybookData) {
    const client = getSanityClient(requirements);
    const query = `*[_type == "page" && (path.current=="${requirements.path}") && !(_id in path("drafts.**"))][0]`;
    const results = await client.fetch(query);
    const content = results?.content?.[0];
    const travelPlannerRef = content?.travelPlannerRef?._ref;
    const travelPlannerResultsRef = content?.travelPlannerResultsRef?._ref;

    travelPlanner = travelPlannerRef
      ? await client.fetch(`*[_id == "${travelPlannerRef}" && !(_id in path("drafts.**"))][0]`)
      : undefined;

    travelPlannerResults = travelPlannerResultsRef
      ? await client.fetch(`*[_id == "${travelPlannerResultsRef}" && !(_id in path("drafts.**"))][0]`)
      : undefined;
  }

  const journeys = await makeAnAlgoliaSearch(
    {index: "journeys"},
    requirements,
    [],
    "",
  ) || [];

  return {
    travelPlanner,
    travelPlannerResults,
    journeys
  };
}

const TravelPlanerHub = {Renderer, preloader}

export default TravelPlanerHub;
export {
  Renderer,
  preloader,
}
