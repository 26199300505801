import { useEffect, useState } from 'react';
import { SeoProps, updateMetaTags } from './tools';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    isMobile: windowSize.width < 768,
    isTablet: windowSize.width >= 768 && windowSize.width < 1024,
    isDesktop: windowSize.width >= 1024,
  };
};

export const useMetaTags = (seoProps: SeoProps) => {
  useEffect(() => {
    if (seoProps) {
      updateMetaTags(seoProps);
    }
  }, [seoProps]);
};

export { useWindowSize };
