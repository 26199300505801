export default {
  key: 'correze',
  canonicalUrl: 'https://lepass.tourismecorreze.com',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 681,
  icon: 'correze.ico',
  headerLogo: 'correze.png',
  footerLogo: 'correze_monochrome.png',
  axeptioClientId: '',
  axeptioCookiesVersion: 'tourismecorreze.com',
  clientUrl: 'https://detenteur.tourismecorreze.com',
  proUrl: 'https://partenaire.tourismecorreze.com',
  sanity: {
    base: {
      projectId: '398hll8p',
      dataset: 'production',
      apiVersion: '2022-12-01',
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        'skM4GSa6TPxDYz688OEswzBlm52L2JxNcH7G8tbZLloT7w2WM7DhgMtCdD0q5P64jxT6hf2nlVmvrg5Y8UgF21tQhF5ueCuMgmaWbajYw4XWXGam1rrwIC9wdPKDuIUWwdNt5SwVemfgFc5f3IXgBfbVORJ8vwYxGrXQfIjd5ZO3B6aUvGUz',
    },
  },
  dev: {
    clientUrl: 'https://detenteur.tourismecorreze.com',
    proUrl: 'https://partenaire.tourismecorreze.com',
  },
};
