import React from 'react';
import { PAGE_ROUTES } from '../../App';
import { Bell, UserCharentes } from '../Picto/charentes/Icons';

interface FullScreenMenuProps {
  isOpen: boolean;
  closeMenu: () => void;
}

const FullScreenMenu: React.FC<FullScreenMenuProps> = ({ isOpen, closeMenu }) => {
  return (
    <div
      className={`full-screen-menu ${isOpen ? 'is-open' : ''}`}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 1000,
        transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <ul className="menu-content">
        <li className="menu-item">
          <a href={PAGE_ROUTES.AUTH.ACCOUNT}>
            <UserCharentes />
            Compte
          </a>
        </li>
        <li className="menu-item">
          <a href={PAGE_ROUTES.AUTH.ACCOUNT}>
            <Bell />
            Notifications
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FullScreenMenu;
