import React, { useState } from "react";
import Calendar from "react-calendar";
import { useTranslation } from "../../tools/i18n";
import { ChatBotComponentProps } from ".";
import { Picto } from "../Picto";
import { DayNumberSolo } from "./DayNumber";
import { FilterLayout } from "./FiltersLayout";
import { LooseValue } from "react-calendar/dist/cjs/shared/types";
import {TravelPlannerPeriodsOrDatesType} from "./types";

export interface PeriodsOrDatesProps extends ChatBotComponentProps {
  step: TravelPlannerPeriodsOrDatesType;
}

export enum Months {
  january = "Janvier",
  february = "Février",
  march = "Mars",
  april = "Avril",
  may = "Mai",
  june = "Juin",
  july = "Juillet",
  august = "Août",
  september = "Septembre",
  october = "Octobre",
  november = "Novembre",
  december = "Décembre",
}

export const PeriodsOrDates = (props: PeriodsOrDatesProps) => {
  const {
    config,
    step,
    callback,
    stateData,
    back,
    requirements
  } = props;
  const [tabs, setTabs] = useState(1);
  const [tripLength, setTripLength] = useState(stateData?.searchMap?.length || 1)
  const [month, setMonth] = useState("");
  const [startDate, setStartDate] = useState<LooseValue>(new Date())
  const { t, i18n } = useTranslation();

  const maxTripLength = stateData?.selectedFavorite
    ? step.maxDaysFavorite ?? 7
    : step.maxDays ?? 5;

  const handleChangePeriodsOrDates = () => {
    const data = {
      tripLength,
      month: tabs === 1 ? month : undefined,
      startDate: tabs === 2 ? startDate : undefined
    }

    callback(data)
  }

  return (
    <div>
      <FilterLayout
        config={config}
        callback={handleChangePeriodsOrDates}
        title={t("travelPlaner.PeriodsOrDates.title")}
        back={back}
        requirements={requirements}
        disabledNext={(!month && tabs === 1) || (!startDate && tabs === 2)}
        siteConfiguration={props.siteConfiguration}
      >
        <div className="periodsOrDates__container">
          <div className="periodsOrDates__header">
            <div
              onClick={() => (tabs === 1 ? undefined : setTabs(1))}
              className={
                tabs === 1
                ? "periodsOrDates__tabs periodsOrDates__tabs__full"
                  : "periodsOrDates__tabs"
              }
            >
              <div className="periodsOrDates__tabs__pictoText">
                <Picto
                  fill={tabs === 1 ? "#1836C5" : "#6D6D6D"}
                  className="periodsOrDate__tabs__picto"
                  iconKey={"periods"}
                />
                <div className="periodsOrDates__tabs__text">{t("travelPlaner.PeriodsOrDates.periods")}</div>
              </div>
              <div
                className={
                  tabs === 1
                    ? "peridosOrDates__tabs__underline__full peridosOrDates__tabs__underline"
                    : "peridosOrDates__tabs__underline"
                }
                />
            </div>
            <div
              onClick={() => (tabs === 2 ? undefined : setTabs(2))}
              className={
                tabs === 2
                  ? "periodsOrDates__tabs periodsOrDates__tabs__full"
                  : "periodsOrDates__tabs"
              }
            >
              <div className="periodsOrDates__tabs__pictoText">
                <Picto
                  className="periodsOrDate__tabs__picto"
                  fill={tabs === 2 ? "#1836C5" : "#6D6D6D"}
                  iconKey={"dates"}
                />
                <div className="periodsOrDates__tabs__text">{t("travelPlaner.PeriodsOrDates.dates")}</div>
              </div>
              <div
                className={
                  tabs === 2
                  ? "peridosOrDates__tabs__underline__full peridosOrDates__tabs__underline"
                  : "peridosOrDates__tabs__underline"
                }
              />
            </div>
          </div>
        </div>
        {tabs === 1 ? (
          <>
          <div className="periodsMonthContainer">
            {Object.keys(Months).map((m) => (
              <div key={m} className={month === m ? "selectedMonth" : "periodsMonth"} onClick={() => setMonth(m)}>
                <Picto
                  className="periodsMonthPicto"
                  fill="#1836C5"
                  iconKey={"dates"}
                  />
                <div className="periodsMonthText">{t(`travelPlaner.PeriodsOrDates.months.${m}`)}</div>
              </div>
            ))}
           </div>
           <div className="periodsDay">
            <div className="periodsDayText">{t("travelPlaner.PeriodsOrDates.duration")}</div>
            <DayNumberSolo number={tripLength} setNumber={setTripLength} maxLength={maxTripLength}   minLength={stateData.searchMap.length}/>
           </div>
          </>
        ) : undefined}
        {tabs === 2 ? (
          <>
            <Calendar
            value={startDate}
            onChange={setStartDate}
            minDate={new Date()}
            defaultView={'month'}
            prev2Label={requirements?.config?.newUI ? null : "«"}
            next2Label={requirements?.config?.newUI ? null : "»"}
            prevLabel={<Picto iconKey="circle-play" className="prev" />}
            nextLabel={<Picto iconKey="circle-play" />}
            locale={i18n.language.split('_')?.[0]}
            tileClassName="periodsOrDates calendarTile"
            />
            <div className="periodsDay">
              <div className="periodsDayText">{t("travelPlaner.PeriodsOrDates.duration")}</div>
              <DayNumberSolo number={tripLength} setNumber={setTripLength} maxLength={maxTripLength}  minLength={stateData.searchMap.length}/>
            </div>
          </>
        ) : undefined}
      </FilterLayout>
    </div>
  );
};
