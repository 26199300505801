import axios from 'axios';
import { EmailData } from '../components/ContactForm/schema';

export const sendEmail = async (data: EmailData) => {
  try {
    const API_URL = process.env.REACT_APP_EMAIL_API_URL;
    const API_KEY = process.env.REACT_APP_EMAIL_API_KEY;

    const response = await axios.post(
      API_URL,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'API-Key': API_KEY
        }
      }
    );
    
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Réponse inattendue: ${response.status}`);
    }
  } catch (error: any) {
    console.error('Erreur détaillée:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    throw error;
  }
};
