import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { languageMap } from '../../tools/constants/languages';

interface Language {
    code: string;
    name: string;
}

interface LanguageSelectorProps {
    customerID: string;
    endpoint: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ customerID, endpoint }) => {
    const { i18n, t } = useTranslation();
    const [languages, setLanguages] = useState<Language[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchLanguages = async () => {
      try {
        const url = `${endpoint}/api/jsonConfiguration/keys/${customerID}`;
        const response = await fetch(url);
        const codes: string[] = await response.json();
        const validLanguages = codes.filter(code => languageMap.hasOwnProperty(code))
            .map(code => ({
                code: code,
                name: languageMap[code]
            }));
        setLanguages(validLanguages);
      } catch (error) {
        console.error('Error fetching languages:', error);
        setError('Failed to load languages. Please try again later.');
      }
      finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
        fetchLanguages();
    }, [customerID]);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            // console.log("savedLanguage", savedLanguage);
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    const changeLanguage = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            i18n.changeLanguage(selectedOption.value);
            localStorage.setItem('selectedLanguage', selectedOption.value);
        }
    };

    const getLanguageName = () => {
        const langCode = i18n.language.split('-')[0]; // Handles codes like 'en-US' by taking 'en'
        return languageMap[langCode] || 'Select language'; // Default text if no matching entry is found
    };

    const options = languages.map(lang => ({
        value: lang.code,
        label: lang.name
    }));

    return (
        <div className="LanguageSelector">
            {isLoading ? (
                <p>{t('common.loading')}...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <Select
                    value={options.find(option => option.value === i18n.language)}
                    onChange={changeLanguage}
                    options={options}
                    isClearable={false}
                    placeholder={getLanguageName()}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={{
                        container: (baseStyles) => ({
                            ...baseStyles,
                            width: '130px',
                            minWidth: '120px',
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: 'none',
                            fontWeight: '700',
                            fontSize: '14px',
                            boxShadow: 'none',
                            cursor: 'pointer',
                            textAlign: 'right',
                            minHeight: '36px',
                            '&:hover': {
                                backgroundColor: '#f4f1e8',
                            }
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: state.isSelected ? '#f4f1e8' : state.isFocused ? '#f5f5f5' : 'white',
                            color: '#000',
                            cursor: 'pointer',
                            ':active': {
                                backgroundColor: '#f4f1e8'
                            }
                        }),
                        menu: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                            borderRadius: '10px',
                            marginTop: '4px',
                            width: '170px',
                            textAlign: 'center',

                        }),
                        singleValue: (baseStyles) => ({
                            ...baseStyles,
                            color: '#333'
                        }),
                        dropdownIndicator: (baseStyles) => ({
                            ...baseStyles,
                            color: '#666',
                            ':hover': {
                                color: '#333'
                            }
                        }),
                        indicatorSeparator: () => ({
                            display: 'none'
                        })
                    }}
                    
                />
            )}
        </div>
    );
};

export default React.memo(LanguageSelector);
