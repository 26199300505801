import React, { useEffect, useRef, useState } from "react";
import { ChatBotComponentProps } from ".";
import { FilterLayout } from "./FiltersLayout";
import { useTranslation } from "../../tools/i18n";
import { Picto } from "../Picto";
import { DROM, INTERNATIONAL_AGENT_ID } from "../../tools/Constants";
import { Loader } from "@googlemaps/js-api-loader";
import { makeAnAlgoliaSearch } from "../../tools/searchAPI";

type AutocompleteService = google.maps.places.AutocompleteService;

export const Lodging = (props: ChatBotComponentProps) => {
  const { t } = useTranslation();
  const { config, callback, initialData, back, requirements, agentId } = props;
  const [lodging, setLodging] = useState({
    hotel: initialData?.hotel?.name || "",
    address: initialData?.address?.name || "",
  });
  const [hotelPredictions, setHotelPredictions] = useState<any[]>([]);
  const [addressPredictions, setAddressPredictions] = useState<any[]>([]);
  const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const voidRef = useRef<HTMLDivElement>();
  const autocomplete = useRef<AutocompleteService>();
  const sessionToken = useRef(null);
  const hotelInputRef = useRef<HTMLInputElement>();
  const addressInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (typeof window !== "undefined" && window.google) {
      return;
    }

    const loader = new Loader({
      apiKey: "AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8",
      version: "weekly",
      libraries: ["places"],
    });

    loader.load();
  }, []);

  const autoCompleteOption = {
    ...(agentId !== INTERNATIONAL_AGENT_ID
      ? { componentRestrictions: { country: ["fr", ...DROM] } }
      : {}),
    fields: ["address_components", "geometry", "name"],
    sessionToken: sessionToken.current,
  };

  const getPredictions = async (input, types = []) => {
    let r = await autocomplete.current.getPlacePredictions({
      input,
      ...autoCompleteOption,
      types,
    });
    let results: any[] = r.predictions || [];
    return { results, input };
  };

  const handleHotelInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLodging({ hotel: e.target.value, address: "" });
    setAddressPredictions([]);
    const value = e.target.value;

    if (!!!value) {
      setHotelPredictions([]);
      return;
    }

    if (typeof searchDebounceTimeout.current === "number")
      clearTimeout(searchDebounceTimeout.current);
    searchDebounceTimeout.current = setTimeout(async () => {
      const res = await makeAnAlgoliaSearch(
        {
          query: value,
          hitsPerPage: 5,
        },
        requirements,
        [],
        `hebergement_classe_${requirements.partner}:true`
      );

      setHotelPredictions(res?.data?.hits || []);
    }, 200);
  };

  const handleSelectHotelPrediction = async (p: any) => {
    callback({
      hotel: {
        name: p.store_name,
        address: p.adr,
        lat: p._geoloc.lat,
        lng: p._geoloc.lng,
      },
      address: {},
    });
  };

  const handleAddressInput = (e) => {
    setLodging({ hotel: "", address: e.target.value });
    setHotelPredictions([]);
    if (!e.target.value) {
      setAddressPredictions([]);
      return;
    }

    if (!autocomplete.current) {
      if (typeof window !== "undefined" && window.google) {
        autocomplete.current = new google.maps.places.AutocompleteService();
        sessionToken.current =
          new google.maps.places.AutocompleteSessionToken();
      } else {
        return;
      }
    }

    const value = e.target.value;
    getPredictions(value).then((r) => {
      if (r.input !== addressInputRef.current.value) return;
      setAddressPredictions(r.results);
    });
  };

  const handleSelectAddressPrediction = async (p: any) => {
    new google.maps.places.PlacesService(voidRef.current).getDetails(
      {
        placeId: p.place_id,
        fields: autoCompleteOption.fields,
        sessionToken: autoCompleteOption.sessionToken,
      },
      (a) => {
        setLodging({ hotel: "", address: a.name });
        callback({
          hotel: {},
          address: {
            name: a.name,
            address: Object.keys(a.address_components)
              .map((k) => a.address_components[k].long_name)
              .join(", "),
            lat: a.geometry.location.lat(),
            lng: a.geometry.location.lng(),
          },
        });
      }
    );
  };

  return (
    <div className="lodging">
      <Picto iconKey="bed" />
      <FilterLayout
        config={config}
        callback={() => callback(lodging)}
        title={t(`travelPlaner.Lodging.title`)}
        back={back}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
      >
        <div className="optional">{t("travelPlaner.common.optional")}</div>
        <div className="subTitle">{t("travelPlaner.Lodging.subTitle")}</div>
        <div
          className={`searchContainer ${
            hotelPredictions?.length ? "withPredictions" : ""
          }`}
        >
          <div className="inputBlock">
            <Picto iconKey={"location-fr"} fill="#8C7215" />
            <input
              value={lodging.hotel}
              ref={ref => { hotelInputRef.current = ref }}
              onChange={(e) => handleHotelInput(e)}
              placeholder={t("travelPlaner.Lodging.placeholder1")}
            />
          </div>
          {hotelPredictions?.length ? (
            <div className="predictions">
              {hotelPredictions.map((p, i) => (
                <button
                  key={`prediction_${i}`}
                  onClick={() => handleSelectHotelPrediction(p)}
                >
                  <div className="prediction">
                    <Picto iconKey="place" />
                    {p.store_name}
                  </div>
                </button>
              ))}
            </div>
          ) : null}
        </div>

        <div className="separation">{t("travelPlaner.Lodging.or")}</div>
        <div
          className={`searchContainer ${
            addressPredictions?.length ? "withPredictions" : ""
          }`}
        >
          <div className="inputBlock">
            <Picto iconKey={"location-fr"} fill="#8C7215" />
            <input
              value={lodging.address}
              ref={ref => { addressInputRef.current = ref }}
              onChange={(e) => handleAddressInput(e)}
              placeholder={t("travelPlaner.Lodging.placeholder2")}
            />
          </div>
          {addressPredictions?.length ? (
            <div className="predictions">
              {addressPredictions.map((p, i) => (
                <button
                  key={`prediction_${i}`}
                  onClick={() => handleSelectAddressPrediction(p)}
                >
                  <div className="prediction">
                    <Picto iconKey={"place"} />
                    {p.label || p.description}
                  </div>
                </button>
              ))}
            </div>
          ) : null}
        </div>
        <div
          nonce="__nonce__"
          style={{ display: "none" }}
          ref={ref => { voidRef.current = ref }}
        />
      </FilterLayout>
    </div>
  );
};
