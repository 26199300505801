import React, { useEffect, useRef, useState } from 'react';
import { dynamicClassName } from '../../tools/tools';
import FloatingSearchBarButton from './FloatingSearchBarButton';

interface FloatingSearchBarInputProps {
  placeholder?: string;
  input: string;
  isFocus: boolean;
  onInputChange: (value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void;
  inputValidation: (value: string) => void;
  isDefault?: boolean;
  onFocus?: (value: boolean) => void;
}

const FloatingSearchBarInput: React.FC<FloatingSearchBarInputProps> = ({
  isFocus,
  placeholder,
  input,
  onKeyDown,
  onInputChange,
  inputValidation,
  isDefault,
  onFocus
}) => {
  const searchBarRef = useRef(null);
  const [isFloating, setIsFloating] = useState<boolean>(false);

  const handleClickOutside = (event) => {
    if(onFocus === undefined) return;
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      onFocus(false);
    } else {
      onFocus(true);
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 200; // Ajustez cette valeur selon vos besoins

      if (window.scrollY > scrollThreshold) {
        setIsFloating(true);
      } else {
        setIsFloating(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`floatingSearchBar-container ${dynamicClassName('floatingSearchBar-container', isFocus)} ${isFloating ? 'is-floating' : ''}`}
      ref={searchBarRef}
    >
      <div className={dynamicClassName('floatingSearchBar-content', isFocus)}>
        <input
          type="text"
          placeholder={placeholder || ''}
          onKeyDown={(e) => onKeyDown(e, input)}
          value={input}
          onChange={(e) => onInputChange(e.target.value)}
          onSubmit={() => inputValidation(input)}
        />

        <button className="floatingSearchBar-button">
          <FloatingSearchBarButton
            isFocus={isFocus}
            query={input}
            onSubmit={inputValidation}
            isDefault={isDefault}
          />
        </button>
      </div>
    </div>
  );
};

export default FloatingSearchBarInput;
