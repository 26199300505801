import React, { useEffect, useRef } from 'react';
import { loadMap } from '../../../tools/apiTools';
import { useTranslation } from '../../../tools/i18n';
import { CustomDataStore } from '../../../tools/providersAPI';
import { capitalize, getFormattedEuro } from '../../../tools/tools';
import ButtonV2 from '../../ButtonV2';
import ContainerShopV2 from '../Container';
import DetailShopV2, { DetailShopV2Props } from '../Detail';

export interface MainInfosShopV2Props {
  seller: { [key: string]: any };
  data: { [key: string]: any };
  customData: CustomDataStore;
}

const MainInfosShopV2: React.FC<MainInfosShopV2Props> = ({ seller, data, customData }) => {
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);

  const { frontCateg } = seller;
  const lat = seller?.latitude ?? data?.latitude;
  const lng = seller?.longitude ?? data;
  const cStore = customData?.store ?? {};
  const details: DetailShopV2Props[] = [
    { icon: 'mapPin', title: 'Adresse', value: data?.formattedAddress },
    {
      icon: 'phoneV2',
      title: t('shopV2.mainInfos.phone'),
      value: cStore.phone ?? seller?.phone ?? data?.phone,
      link: {
        href: `tel:${cStore.phone ?? seller?.phone ?? data?.phone}`,
        target: '_self',
      },
    },
    {
      icon: 'envelopeV2',
      title: t('shopV2.mainInfos.email'),
      value: cStore.email ?? seller?.email ?? data?.email,
      link: {
        href: `mailto:${cStore.email ?? seller?.email ?? data?.email}`,
        target: '_self',
      },
    },
    {
      icon: 'schedules',
      title: t('shopV2.mainInfos.schedules'),
      children: cStore.schedule ? (
        <ButtonV2
          text={t('shopV2.mainInfos.seeAllSchedules')}
          variant="outline"
          link="#schedules"
          linkTarget="_self"
        />
      ) : undefined,
    },
    {
      icon: 'euroV2',
      title: t('shopV2.mainInfos.prices'),
      value:
        cStore.minimumPrice > 0
          ? capitalize(
              t('shopV2.hero.fromPrice', {
                value: getFormattedEuro(cStore.minimumPrice),
              })
            )
          : (null ?? data?.prices ?? null),
      children:
        cStore.minimumPrice > 0 || data?.prices ? (
          <ButtonV2
            text={t('shopV2.mainInfos.seeAllPrices')}
            variant="outline"
            link="#prices"
            linkTarget="_self"
          />
        ) : undefined,
    },
    {
      icon: 'website',
      title: t(`shopV2.mainInfos.website`),
      value: cStore.website ?? seller?.website ?? data?.website,
      link: { href: cStore.website ?? seller?.website ?? data?.website },
    },
  ];

  useEffect(() => {
    if (!divRef.current) return;
    loadMap(divRef.current, { lat, lng }, [{ geoloc: { lat, lng }, category: frontCateg }], 15);
  }, []);

  return (
    <ContainerShopV2>
      <div className="MainInfosShopV2">
        <div className="MainInfosShopV2__details">
          {details
            .filter((d) => !!d.value || !!d.children)
            ?.map((d, i) => <DetailShopV2 key={i} {...d} />)}
        </div>
        <div
          ref={(ref) => {
            divRef.current = ref;
          }}
          className="MainInfosShopV2__map"
        />
      </div>
    </ContainerShopV2>
  );
};

export default MainInfosShopV2;
