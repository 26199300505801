export default {
  key: 'ariege',
  canonicalUrl: 'https://www.avantagesariege.fr',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 675,
  icon: 'ariege.png',
  headerLogo: 'ariege.png',
  footerLogo: 'ariege.png',
  axeptioClientId: '643d0d11013505afb4175189',
  axeptioCookiesVersion: 'avantagesariege-fr',
  clientUrl: 'https://client.avantagesariege.fr/',
  proUrl: 'https://pro.avantagesariege.fr/',
  sanity: {
    base: {
      projectId: 'it0wwxe8',
      dataset: 'production',
      apiVersion: '2022-12-01',
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        'skHKyrLaQGDm7S3k2pEf9y6TL6n0pQhhkFofbZL2KWMgfZiAuZdA6Z4egV6je28wzwTCT13eIDtXJfLKhYS7LfMVc1ae6uGyVRpbHl2dlfjoGPGcx7FWoLWMfnTtseKRw770EcOE5tmueLuVrNEi3IpTUlZnPgMcgRBftZQW0b6oWgLDpON7',
    },
  },
  dev: {
    clientUrl: 'http://dev.avantagesariege.fr/',
    proUrl: 'http://dev.avantagesariege.fr/',
  },
};
