import React from 'react';
import { CustomDataStore } from '../../../tools/providersAPI';
import { Picto } from '../../Picto';

export interface LabelsShopV2Props {
  labels: CustomDataStore['store']['labels'];
}

const LabelsShopV2: React.FC<LabelsShopV2Props> = ({ labels }) => {
  if (!labels?.length) return null;

  return (
    <div className="LabelsShopV2">
      {labels.map((label) => (
        <Picto key={label} iconKey={label} />
      ))}
    </div>
  );
};

export default LabelsShopV2;
