import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTracking } from "../../tools/analytics";
import PictoButton from "./PictoButton";
import Swal from 'sweetalert2';
export interface FavoritesProps {
  inputs: {
    search?: string
    searchMap?: string
    startDate: string
    tripLength: number
  }
}

const Favorites = (props: FavoritesProps) => {
    const { trackEvent } = useTracking();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
      const currentUrl = window.location.href;
      const existingFavorites = document.cookie
        .split('; ')
        .find(row => row.startsWith('travelFavorites='));

      if (existingFavorites) {
        try {
          const favoritesArray = JSON.parse(decodeURIComponent(existingFavorites.split('=')[1]));
          const isFavorited = favoritesArray.some(f => f.url === currentUrl);
          setIsActive(isFavorited);
        } catch (e) {
          console.error('Error parsing favorites:', e);
        }
      }
    }, []);

    const saveToFavorites = () => {
      trackEvent('toggle_favorite');
      const currentUrl = window.location.href;
      
      let favoritesArray = [];
      const existingFavorites = document.cookie
        .split('; ')
        .find(row => row.startsWith('travelFavorites='));

      if (existingFavorites) {
        try {
          favoritesArray = JSON.parse(decodeURIComponent(existingFavorites.split('=')[1]));
          const existingIndex = favoritesArray.findIndex(f => f.url === currentUrl);
          
          if (existingIndex !== -1) {
            favoritesArray.splice(existingIndex, 1);
            setIsActive(false);
          } else {
            const newFavorite = {
              destination: props.inputs.search || '',
              depart: props.inputs.startDate || moment().format('DD/MM'),
              duration: props.inputs.tripLength || 1,
              url: currentUrl
            };
            favoritesArray.push(newFavorite);
            setIsActive(true);
          }
        } catch (e) {
          console.error('Error parsing favorites:', e);
          favoritesArray = [];
        }
      } else {
        const newFavorite = {
          destination: props.inputs.search || '',
          depart: props.inputs.startDate || moment().format('DD/MM'),
          duration: props.inputs.tripLength || 1,
          url: currentUrl
        };
        favoritesArray.push(newFavorite);
        setIsActive(true);
      }

      favoritesArray = favoritesArray.filter(favorite => 
        favorite.destination && 
        favorite.depart && 
        favorite.depart !== 'Invalid date' &&
        favorite.duration > 0
      );

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      document.cookie = `travelFavorites=${encodeURIComponent(JSON.stringify(favoritesArray))}; path=/; expires=${expirationDate.toUTCString()}`;
      console.log('document.cookie', document.cookie);

      Swal.fire({
        title: isActive ? 'Retiré des favoris' : 'Sauvegardé !',
        text: isActive ? 'Votre itinéraire a été retiré des favoris' : 'Votre itinéraire a été ajouté aux favoris',
        icon: isActive ? 'error' : 'success',
        timer: 2000,
        showConfirmButton: false
      });
    };

    return (
      <PictoButton
        className={`favorite-button ${isActive ? "active" : ""}`}
        iconKey="heart"
        onClick={saveToFavorites}
        
      />
    );
};

export default Favorites;
